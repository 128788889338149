import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { destroySelect, initializeSelect, toggleSelection } from '../actions/selection';


const withSelection = (WrappedComponent, options) => class extends Component {
  static propTypes = {
    toggleSelection: PropTypes.func.isRequired,
    initializeSelect: PropTypes.func.isRequired,
    destroySelect: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { initializeSelect } = this.props;
    initializeSelect(options.name);
  }

  componentWillUnmount() {
    const { destroySelect } = this.props;
    destroySelect(options.name);
  }

  render() {
    const { toggleSelection, selection, ...restProps } = this.props;
    const { name } = options;
    const selectedItems = selection[name] || [];
    return (
      <WrappedComponent
        {...restProps}
        toggleSelection={toggleSelection(name)}
        selectedItems={selectedItems}
      />
    );
  }
};

const mapStateToProps = state => ({
  selection: state.selection,
});

const mapDispatchToProps = dispatch => ({
  toggleSelection: name => id => dispatch(toggleSelection(id, name)),
  initializeSelect: name => dispatch(initializeSelect(name)),
  destroySelect: name => dispatch(destroySelect(name)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSelection,
);
