import React from 'react';
import PropTypes from 'prop-types';


const FieldTooltip = ({ text }) => (
  <div className="render-field-tooltip">
    <div className="tooltip-text">{text}</div>
  </div>
);


FieldTooltip.defaultProps = {
  text: '',
};

FieldTooltip.propTypes = {
  text: PropTypes.string,
};

export default FieldTooltip;
