import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';


const SignUpSuccessPage = () => {
  const email = new URLSearchParams(document.location.search).get('email');
  return (
    <div className="sign-up-success-page full-height-wrap section-grey">
      <Helmet>
        <title>Письмо успешно отправлено | Jobs Widget</title>
      </Helmet>
      <div className="sign-up-success-content">
        <div className="icon-wrap">
          <FontAwesomeIcon
            className="item-icon"
            icon="check-circle"
          />
        </div>
        <h4 className="sign-up-success-page-title">
          Письмо успешно отправлено на
          {' '}
          <span className="light-green">{email}</span>
        </h4>
        <p className="sign-up-success-page-text">Чтобы активировать аккаунт перейдите по ссылке в письме</p>
        <Link to="/login">
          <button
            type="button"
            className="button button_size_sm"
          >
            Войти на сайт
          </button>
        </Link>
      </div>
    </div>
  );
};

export default SignUpSuccessPage;
