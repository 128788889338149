import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Input } from 'reactstrap';
import Fuse from 'fuse.js';
import ResponseItem from './ResponseItem';
import withPagination from '../containers/withPagination';
import withSelection from '../containers/withSelection';
import ConfirmModal from './ConfirmModal';


class ResponsesList extends React.Component {
  constructor(props) {
    super(props);
    const { items: responses } = props;
    this.state = {
      filteredResponses: responses,
      activatedMenu: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const { selectedItems } = this.props;
    const { activatedMenu } = this.state;
    if (activatedMenu && (!selectedItems || selectedItems.length !== prevProps.selectedItems.length)) {
      this.handleActivatedMenu();
    }
  };

  handleActivatedMenu = () => {
    this.setState(state => ({ activatedMenu: !state.activatedMenu }));
  };

  render() {
    const {
      items: responses,
      toggleSelection,
      selectedItems,
    } = this.props;

    const {
      filteredResponses,
    } = this.state;

    const { activatedMenu } = this.state;

    const selectAllItemsOnPage = () => {
      const responsesIds = filteredResponses.map(response => response.id);
      const notSelectedItemsOnPage = responsesIds.filter(id => !selectedItems.includes(id));
      if (notSelectedItemsOnPage.length) {
        notSelectedItemsOnPage.forEach(id => toggleSelection(id));
      } else {
        responsesIds.forEach(id => toggleSelection(id));
      }
    };

    const onFilterChange = (evt) => {
      const { target: { value } } = evt;
      if (value.length === 0) {
        this.setState({ filteredResponses: responses });
      } else {
        const fuse = new Fuse(responses, { keys: ['fields.first_name', 'fields.last_name', 'field.middle_name'] });
        const result = fuse.search(value).map(value => value.item);
        this.setState({ filteredResponses: result });
      }
    };
    const responseItems = filteredResponses.length
      ? filteredResponses.map(response => (
        <ResponseItem
          key={response.id}
          response={response}
          toggleSelection={toggleSelection}
          isSelected={selectedItems.includes(response.id)}
        />
      ))
      : <div className="content-container__empty">Здесь будут отображаться отклики кандидатов на ваши вакансии</div>;

    const selectAllButton = (
      <button
        type="button"
        className="button-add-item span vacancies-icons-wrap-button"
        onClick={selectAllItemsOnPage}
      >
        <span className="button-text">Выбрать все</span>
      </button>
    );

    const multipleChoicePanel = (
      <React.Fragment>
        <ConfirmModal
          type="icon-text"
          buttonText="удалить"
          headerText={`Удалить выбранные отклики (${selectedItems.length})?`}
          modalAction={() => {}}
        />
        <button
          type="button"
          className="button-add-item span"
          onClick={() => {}}
        >
          <FontAwesomeIcon
            className="item-icon"
            icon="check-square"
          />
          <span className="vacancy-item-description-elem">отметить как прочитанное</span>
        </button>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <label className="search-response-container">
          <FontAwesomeIcon
            className="item-icon"
            icon="search"
          />
          <Input
            name="response_filter"
            className="search-response__input"
            onChange={onFilterChange}
            type="text"
            style={{ backgroundColor: 'white' }}
            placeholder="Поиск по ФИО"
          />
        </label>
        <div className="vacancies-page-sort">
          {selectedItems.length !== 0 && (
            <React.Fragment>
              <span className="icons-wrap-button multiple-select">
                <span className="mr-1">
                  {selectedItems.length}
                  {' '}
                  выбрано
                </span>
                <button
                  type="button"
                  className="button-add-item span button-multiple m-0 ml-2"
                  onClick={() => {}}
                >
                  <FontAwesomeIcon
                    className="button-text"
                    icon="times"
                  />
                </button>
              </span>
              <div className="mobile-button-multiple-menu">
                <button
                  type="button"
                  className="button-add-item span"
                  onClick={this.handleActivatedMenu}
                >
                  <span className="vacancy-item-description-elem">действия</span>
                  <FontAwesomeIcon
                    className="item-icon"
                    icon="ellipsis-v"
                  />
                </button>
                <Collapse
                  isOpen={activatedMenu}
                  className="mobile-multiple-menu"
                >
                  {selectAllButton}
                  {multipleChoicePanel}
                </Collapse>
              </div>
            </React.Fragment>
          )}
          <div className="button-select-all mr-2">
            {selectAllButton}
          </div>
          {selectedItems.length !== 0 && (
            <div className="vacancies-page-multiple mb-1 ml-3">
              {multipleChoicePanel}
            </div>
          )}
        </div>
        <div className="content-container content-container_padding_sm">
          {responseItems}
        </div>
      </React.Fragment>
    );
  }
}

ResponsesList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleSelection: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
};


export default withPagination(withSelection(ResponsesList, { name: 'selectedResponses' }), 'responses');
