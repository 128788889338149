
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import appConfig from '../configs/appConfig';
import ActivateAccountRoleForm from '../forms/ActivateAccountRoleForm';
import { employeeStatusActivation, saveUserData } from '../actions/app';


const AccountActivationRequestAlert = (props) => {
  const {
    location: { pathname, search },
    email,
    password,
    saveUserData,
  } = props;
  const isLoginAlert = pathname.includes('login');

  const handleSubmit = (values) => {
    const { accountActivate } = props;
    const userData = isLoginAlert ? { email, password } : values;
    if (!isLoginAlert) {
      const { email, password } = values;
      saveUserData(email, password);
    }

    accountActivate(userData);
  };


  let title = 'Вы пытаетесь зарегистрироваться как соискатель.';
  let descriptionPart = 'На данный момент  вы уже зарегистрированы как работодатель.';

  if (isLoginAlert) {
    title = 'Вы пытаетесь зайти в личный кабинет соискателя';
    descriptionPart = 'На данный момент у вас уже есть доступ к личному кабинету работодателя.';
  }

  const siteUrl = appConfig.JOB_SITE_URL;
  const locationSearch = new URLSearchParams(search);
  const initialValues = {
    email: locationSearch.get('email') || null,
  };

  return (
    <div className="full-height-wrap section-grey text-center">
      <div className="auth-content">
        <p>
          {title}
        </p>
        <p>
          {descriptionPart} Для того, чтобы расширить свои права и
          получить доступ к личному кабинету соискателя подтвердите
          свои данные и нажмите <b>Продолжить</b>.
        </p>
        <p>
          Если вы желаете зайти в личный кабинет работодателя
          нажмите <b>Вход для работодателя</b>.
        </p>
        {isLoginAlert
          ? (
            <button
              type="button"
              className="button mt-4"
              onClick={handleSubmit}
            >
              Продолжить
            </button>
          )
          : (
            <ActivateAccountRoleForm
              onSubmit={handleSubmit}
              initialValues={initialValues}
            />
          )}
        <button
          type="button"
          className="button button_color_dark-blue mt-3"
          onClick={() => window.location.assign(`${siteUrl}/login`)}
        >
        Вход для работодателя
        </button>
      </div>
    </div>
  );
};


AccountActivationRequestAlert.defaultProps = {
  email: null,
  password: null,
};

AccountActivationRequestAlert.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  location: PropTypes.object.isRequired,
  accountActivate: PropTypes.func.isRequired,
  saveUserData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  accountActivate: values => dispatch(employeeStatusActivation(values)),
  saveUserData: (email, password) => dispatch(saveUserData(email, password)),
});

const mapStateToProps = (state) => {
  const { app: { email, password } } = state;
  return {
    email,
    password,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivationRequestAlert);
