import axios from '../api';
import {
  GET_ALL_VACANCIES_FAIL,
  GET_ALL_VACANCIES_REQUEST,
  GET_ALL_VACANCIES_SUCCESS,
  GET_VACANCY_FAIL,
  GET_VACANCY_REQUEST,
  GET_VACANCY_SUCCESS,
} from '../constants';


export const getAllVacancies = () => ({
  name: 'allVacancies',
  types: [GET_ALL_VACANCIES_REQUEST, GET_ALL_VACANCIES_SUCCESS, GET_ALL_VACANCIES_FAIL],
  callApi: () => axios.get('/all_vacancies/'),
});

export const getVacancy = id => ({
  name: 'activeVacancy',
  types: [GET_VACANCY_REQUEST, GET_VACANCY_SUCCESS, GET_VACANCY_FAIL],
  callApi: () => axios.get(`/all_vacancies/${id}/`),
});
