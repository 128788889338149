import axios from 'axios';
import { currencyOptions, experienceOptions, specializationOptions } from '../forms/constants/vacancyFormConstants';


export const TRANSLATE_TO_RUSSIAN = {
  schedules: {
    '': 'Не указано',
    flexible: 'Гибкий график',
    remote: 'Удаленная работа',
    fullDay: 'Полный день',
    shift: 'Сменный график',
    flyInFlyOut: 'Вахтовый метод',
  },
  employments: {
    '': 'Не указано',
    full: 'Полная занятость',
    part: 'Частичная занятость',
    project: 'Проектная работа',
    volunteer: 'Волонтерство',
    probation: 'Стажировка',
  },
  experiences: {
    '': 'Не указано',
    noExperience: 'Опыт не требуется',
    between1And3: 'От 1 до 3 лет',
    between3And6: 'От 3 до 6 лет',
    moreThan6: 'Более 6 лет',
  },
  vacancyFieldTypes: {
    text: 'текст',
    textarea: 'многострочный текст',
    number: 'число',
    tel: 'телефон',
    email: 'email',
    file: 'файл',
  },
  grade: {
    secondary: 'Среднее',
    secondarySpecial: 'Среднее специальное',
    incompleteHigher: 'Неоконченное высшее',
    higher: 'Высшее',
    bachelor: 'Бакалавар',
    master: 'Магистр',
    candidateOfSciences: 'Кандидат наук',
    doctorOfSciences: 'Доктор наук',
  },
  language: {
    A1: 'A1 - Начальный',
    A2: 'A2 - Элементарный',
    B1: 'B1 - Средний',
    B2: 'B2 - Средне-продвинутый',
    C1: 'C1 - Продвинутый',
    C2: 'C2 - В совершенстве',
    native: 'Носитель языка',
  },
  gender: {
    male: 'мужчина',
    female: 'женщина',
  },
};

/* eslint-disable max-len*/
export const TOOLTIP_TEXTS = {
  field: 'Добавьте новый тип поля в список. Например, поле "Ваше хобби". Для этого укажите имя поля и его тип.',
  hiringTemplate: 'Шаблон найма определяет путь, по которому проходит кандидат, прежде чем попадет на работу в компанию. Создать свой собственный шаблон найма Вы можете в разделе "Настройки - Настройки найма".',
  jwfieldSet: 'Набор полей описывает поля, которые заполнит кандидат, чтобы откликнуться на вакансию. Например, поле "Имя", "Телефон", "Резюме". Вы можете создать собственный набор в разделе "Настройки - Настройка полей".',
  fieldSetName: 'Набор полей описывает поля, которые кандидат сможет заполнить, чтобы откликнуться на вакансию. Добавьте новое поле из списка существующих типов полей. Чтобы создать новый тип поля нажмите "Создать новый тип поля".',
  hiringTemplateName: 'Процесс найма состоит из шагов, по которым проходит кандидат (Выслано тестовое, Приглашен на собеседование и т.д.). Создайте шаблон для компании, добавив шаги, через которые проходит кандидат. Для каждого шага укажите состояние, в которое кандидат может перейти из текущего состояния. Например, из состояния "Новый", кандидат может перейти в состояние "Отказано" или "Приглашен на собеседование".',
  hiringTemplateStartState: 'Начальное состояние - это то состояние, которое присваивается новому кандидату.',
  hiringTemplateFrom: 'Откуда - это состояние, в котором в данный момент находится кандидат. В карточке отклика оно отображается в поле "Статус кандидата".',
  hiringTemplateTo: 'Куда - это состояние, в которое кандидат может перейти из текущего состояния. В карточке отклика будет кнопка, по нажатию на которую кандидат перейдет в это состояние.',
  hiringTemplateButton: 'Название кнопки - это надпись на кнопке для изменения состояния, расположенная на странице отклика. Статус кандидата и название кнопки могут не совпадать. Например, статус "Приглашен на собеседование" и кнопка "Пригласить на собеседование".',
  sortOrder: 'По умолчанию вакансии на виджете отображаются в порядке их создания. Выше будет располагаться более новая вакансия. Вы можете сами задать порядок сортировки. Чем меньше цифра в поле, тем выше отображается вакансия. Число 100 вписано по умолчанию для простоты. Пример: нужно поднять старую вакансию наверх. Вписываем в это поле 99 - вакансия имеет высший приоритет, т.к. у остальных вакансий приоритет 100. Если нужно поднять наверх другую вакансию, просто вписываем 98. При этом не нужно менять 99 на 100 в предыдущей вакансии как в случае если бы у всех вакансий был бы приоритет по умолчанию 1.',
  question: 'Вы можете добавить в вакансию вопрос для проверки знаний кандидата. Кандидат увидит в виджете ваш вопрос и текстовое поле для ответа на него. Создать вопрос Вы можете в разделе Настройки - Настройка вопросов',
  questionsPage: 'В этом разделе вы можете создать список вопросов для проверки ваших кандидатов. Вопросы из этого списка можно прикрепить к вакансии в карточке создания вакансии.',
  hhEmployerUrl: 'Зайдите на страницу своей компании на hh.ru и скопируйте ссылку. Она должна быть в формате hh.ru/employer/XXX, где XXX - номер компании.',
  reportPage: 'В результатах учитываются отклики, когда-либо находившиеся в статусе.',
  editingField: 'В данном разделе вы можете создавать, редактировать и удалять свои собственные поля.',
  editingHiringTemplateState: 'В данном разделе вы можете создавать, редактировать и удалять свои собственные состояния.',
};
/* eslint-enable max-len*/

export const getGender = gender => TRANSLATE_TO_RUSSIAN.gender[gender] || null;
export const getGenderSuffix = (gender) => {
  if (gender === 'male') {
    return 'родился';
  } if (gender === 'female') {
    return 'родилась';
  }
  return 'родился(лась)';
};

export const setAuthorizationToken = (token) => {
  if (token) {
    axios.defaults.headers.authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.authorization;
    localStorage.removeItem('token');
  }
};

export const createOption = label => ({
  label,
  value: label,
});

export const scrollToElement = (className, scrollOffset = -100) => {
  const element = document.getElementsByClassName(className)[0];
  if (element) {
    element.scrollIntoView();
    window.scrollBy(0, scrollOffset);
  }
};

export const formatPhone = (phone) => {
  const onlyNums = phone.replace(/[^\d]/g, '');
  if (onlyNums.length) {
    if (onlyNums.length < 7) {
      return onlyNums;
    }
    if (onlyNums.length === 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 7)}`;
    }
    if (onlyNums.length === 8) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}`;
    }
    if (onlyNums.length === 9) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 9)}`;
    }
    if (onlyNums.length === 10) {
      return `+7 (${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 8)}-${onlyNums.slice(8, 10)}`;
    }
    if (onlyNums.length === 11) {
      const firstNum = (parseInt(onlyNums[0], 10) === 8 && onlyNums.length === 11) ? 7 : onlyNums[0];
      return `+${firstNum} (${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, 7)}-${onlyNums.slice(7, 9)}-`
        + `${onlyNums.slice(9, 11)}`;
    }
    const firstNum = (parseInt(onlyNums[0], 10) === 8 && onlyNums.length === 11) ? 7 : onlyNums[0];
    return `+${firstNum} (${onlyNums.slice(1, 4)}) ${onlyNums.slice(4, onlyNums.length)}`;
  }
  return '';
};

export const getCurrencySign = currency => (
  currency ? currencyOptions.find(({ value }) => value === currency).sign : ''
);

export const getSalaryText = (salary_from, salary_to, currency) => {
  const currencySign = getCurrencySign(currency);

  if (salary_from && salary_to) {
    return `${salary_from} - ${salary_to}${currencySign}`;
  }
  if (salary_from) {
    return `От ${salary_from}${currencySign}`;
  }
  if (salary_to) {
    return `До ${salary_to}${currencySign}`;
  }

  return 'Зарплата не указана';
};

export const getExperienceText = experience => experienceOptions.find(({ value }) => value === experience).label;
export const getSpecialization = (specialization) => {
  if (specialization) {
    return specializationOptions.find(({ value }) => value === specialization).label;
  }
  return specialization;
};

export const stripHtml = (description) => {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = description;
  return temporalDivElement.textContent || temporalDivElement.innerHTML || '';
};
