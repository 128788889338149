import React from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import LanguageItem from '../../components/LanguageItem';
import useUniqueKeys from '../../hooks/useUniqueKeys';


const renderLanguage = (props) => {
  const { fields, languageLevels, languages } = props;
  const [onDelete, modFields, setWasDeleted] = useUniqueKeys(languages);
  const handleFieldDelete = (id, index) => {
    fields.remove(index);
    onDelete(id);
  };

  const handleFieldAdd = () => {
    fields.push({});
    setWasDeleted(false);
  };

  return (
    <>
      {modFields.map(({ id, value }, index) => (
        <LanguageItem
          key={id}
          member={`languages[${index}]`}
          languageData={value}
          onDelete={() => handleFieldDelete(id, index)}
          languageLevels={languageLevels}
        />
      ))}
      <button
        type="button"
        className="button button_plus"
        onClick={handleFieldAdd}
      >
        <span className="button-icon">+</span>
        <span className="button-text">Добавить язык</span>
      </button>
    </>
  );
};

renderLanguage.defaultProps = {
  languageLevels: [],
};

renderLanguage.propTypes = {
  fields: PropTypes.object.isRequired,
  languageLevels: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('editResume');
  const languages = selector(state, 'languages');
  return {
    languages,
  };
};

export default connect(mapStateToProps, null)(renderLanguage);
