import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import './styles';
import store from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';


if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV !== 'demo') {
  Sentry.init({ dsn: 'https://88b8fb5c1ac6421a82514b6ad601c832@sentry.io/1371231' });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
