import React from 'react';
import { Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'url-search-params-polyfill';
import 'es7-object-polyfill';

import './utils/fontAwesome';
import MainRouter from './routes/MainRouter';
import history from './routes/history';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';


const App = () => (
  <Router history={history}>
    <ErrorBoundary>
      <Helmet>
        <title>Jobs Widget</title>
      </Helmet>
      <ScrollToTop>
        <MainRouter />
      </ScrollToTop>
    </ErrorBoundary>
  </Router>
);

export default App;
