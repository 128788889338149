import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loginUser } from '../actions/app';


const AccountActivationRequestAlert = (props) => {
  const loginUser = () => {
    const { email, password, loginUser } = props;
    loginUser(email, password);
  };

  return (
    <div className="full-height-wrap section-grey text-center">
      <div className="account-activation-success-wrapper">
        <p>Вы успешно активировали аккаунт соискателя</p>
        <p>Чтобы продолжить нажмите на кнопку</p>
        <button type="button" onClick={loginUser} className="button mt-3">Войти</button>
      </div>
    </div>
  );
};


AccountActivationRequestAlert.propTypes = {
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  loginUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password) => dispatch(loginUser(email, password)),
});

const mapStateToProps = (state) => {
  const { app: { email, password } } = state;
  return {
    email,
    password,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountActivationRequestAlert);
