import { createErrorsReducer } from '../utils/reducerUtils';
import {
  CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
  CHANGE_PASSWORD_FAIL,
  DELETE_HIRING_TEMPLATE_FAIL,
  LOGIN_USER_FAIL,
  REGISTER_USER_FAIL,
  RESET_ERROR,
  RESTORE_PASSWORD_FAIL,
  PUBLISH_VACANCY_RESTRICT,
  GET_ALL_VACANCIES_FAIL,
  GET_VACANCY_FAIL,
  CHECK_USER_HAVE_ACCOUNT_FAIL,
} from '../constants';


const resetError = (state, action) => {
  const newState = { ...state };
  delete newState[action.name];
  return newState;
};

const initialState = {};

export default createErrorsReducer(initialState, [
  {
    type: REGISTER_USER_FAIL,
    name: 'registerUserError',
  },
  {
    type: LOGIN_USER_FAIL,
    name: 'loginUserError',
  },
  {
    type: CHANGE_PASSWORD_FAIL,
    name: 'changePasswordFail',
  },
  {
    type: CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
    name: 'changePasswordAfterRestoreError',
  },
  {
    type: RESTORE_PASSWORD_FAIL,
    name: 'restorePasswordError',
  },
  {
    type: DELETE_HIRING_TEMPLATE_FAIL,
    name: 'deleteHiringTemplateError',
  },
  {
    type: PUBLISH_VACANCY_RESTRICT,
    name: 'editVacancyError',
  },
  {
    type: GET_ALL_VACANCIES_FAIL,
    name: 'getAllVacanciesFail',
  },
  {
    type: GET_VACANCY_FAIL,
    name: 'getVacancyError',
  },
  {
    type: CHECK_USER_HAVE_ACCOUNT_FAIL,
    name: 'userHaveAccountFail',
  },
], {
  [RESET_ERROR]: resetError,
});
