import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element, Link, animateScroll as scroll } from 'react-scroll';
import { CSSTransition } from 'react-transition-group';

import VacanciesPageFilterContainer from '../components/VacanciesPageFilterContainer';
import '../styles/layout/AllVacanciesPage.css';
import AllVacanciesList from '../components/AllVacanciesList';
import { getAllVacancies } from '../actions/vacancies';
import transparent_preloader from '../assets/img/transparent_preloader.gif';
import ErrorMessage from '../components/ErrorMessage';
import PublicHeader from '../components/PublicHeader';
import Footer from '../components/Footer';
import StyledSelect from '../components/StyledSelect';
import history from '../routes/history';
import FieldTooltip from '../components/FieldTooltip';


class AllVacanciesPage extends Component {
  constructor(props) {
    super(props);
    const { vacancies } = this.props;

    this.state = {
      showToolTip: false,
      isVacanciesWasFiltered: false,
      filteredVacancies: vacancies || null,
      timeFilterValue: { label: 'за всё время', value: 'byAllTime' },
    };
  }

  componentDidMount = () => {
    const { getAllVacancies } = this.props;
    getAllVacancies();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { vacancies } = this.props;

    if (vacancies !== prevProps.vacancies) {
      this.onVacanciesUpdate(vacancies);
    }

    const { timeFilterValue: { value }, filteredVacancies } = this.state;

    const { location: { pathname, search } } = this.props;
    const searchParams = new URLSearchParams(search);

    if (value !== prevState.timeFilterValue.value) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }

    if (prevState.filteredVacancies !== filteredVacancies) {
      searchParams.set('page', '1');
      history.push({ pathname, search: `?${searchParams}` });
    }
  }

  onVacanciesUpdate = (newVacancies) => {
    this.setState({ filteredVacancies: newVacancies });
  }

  isTimePeriod = (vacancy) => {
    const { timeFilterValue: { value } } = this.state;

    const vacancyPublishDate = new Date(vacancy.publish_date);
    const currentDate = new Date();

    switch (value) {
      case 'byAllTime':
        return true;
      case 'byYear':
        return vacancyPublishDate.getFullYear() === currentDate.getFullYear();
      case 'byMonth':
        return vacancyPublishDate.getMonth() === currentDate.getMonth()
        && vacancyPublishDate.getFullYear() === currentDate.getFullYear();
      default:
        return true;
    }
  }

  onShowMoreClicked = () => {
    scroll.scrollTo(0);
    setTimeout(() => {
      this.setState({ showToolTip: true });
    }, 1000);
  }

  onSort = (firstVacancy, secondVacancy) => (
    firstVacancy.publish_date > secondVacancy.publish_date ? -1 : 1
  );

  handleFilterChange = (filteredVacancies) => {
    const { isVacanciesWasFiltered, showToolTip } = this.state;
    if (!isVacanciesWasFiltered) {
      this.setState({ isVacanciesWasFiltered: true });
    }

    if (showToolTip) {
      this.setState({ showToolTip: false });
    }

    this.setState({
      filteredVacancies,
    });
  };

  handleTimeFilterChange = (timeFilterValue) => {
    this.setState({
      timeFilterValue,
    });
  };

  render() {
    const {
      filteredVacancies,
      requiredFilters,
      timeFilterValue,
      isVacanciesWasFiltered,
      city,
      showToolTip,
    } = this.state;

    const { vacancies, authComplete } = this.props;

    const timeFilterOptions = [
      { label: 'в этом месяце', value: 'byMonth' },
      { label: 'в этом году', value: 'byYear' },
      { label: 'за всё время', value: 'byAllTime' },
    ];

    const { location, errors } = this.props;
    const isVacanciesLoadFail = errors.hasOwnProperty('getAllVacanciesFail');

    const filteredAndSortedVacancies = filteredVacancies && filteredVacancies
      .filter(this.isTimePeriod)
      .sort(this.onSort);

    const vacanciesToRender = (filteredVacancies) && (isVacanciesWasFiltered
      ? filteredAndSortedVacancies
      : filteredAndSortedVacancies.slice(0, 10));

    return (
      <>
        <Helmet>
          <title>Все вакансии | Jobs Widget</title>
        </Helmet>
        { !authComplete && <PublicHeader />}
        <div className={`all-vacancies-page section-grey ${!authComplete ? 'title-padding' : ''}`}>
          <div className={`all-vacancies-content container ${!authComplete ? 'pb-4' : ''}`}>
            <div className="main-page-title-wrapper col-8 p-0">
              <h1 className="main-page-title all-vacancies-title">
                Доступные вакансии
              </h1>
              {city}
            </div>
            <ErrorMessage errorName="getAllVacanciesFail" />
            <>
              {(filteredVacancies) ? (
                <>
                  <Element name="vacanciesFilters">
                    <CSSTransition
                      in={showToolTip}
                      classNames="tooltip-field"
                      timeout={300}
                      unmountOnExit
                    >
                      <FieldTooltip text="Выберите город, чтобы отобразить больше вакансий" />
                    </CSSTransition>
                    <VacanciesPageFilterContainer
                      vacancies={vacancies}
                      filteredVacancies={filteredAndSortedVacancies}
                      handleFilterChange={this.handleFilterChange}
                      requiredFilters={requiredFilters}
                      pageName="AllVacanciesPage"
                    />
                  </Element>
                  { isVacanciesWasFiltered
                  && (
                    <Row className="time-filters-wrapper">
                      <Col md="6" sm="6" className="vacancy-counter text-color-dark-blue">
                        Найдено вакансий:&nbsp;
                        { filteredAndSortedVacancies && filteredAndSortedVacancies.length }
                      </Col>
                      <Col
                        lg="3" sm="6"
                        col="12"
                        className="time-filter"
                      >
                        <StyledSelect
                          options={timeFilterOptions}
                          value={timeFilterValue}
                          isSearchable={false}
                          onChange={this.handleTimeFilterChange}
                        />
                      </Col>
                    </Row>)
                  }
                  <AllVacanciesList
                    items={vacanciesToRender}
                    location={location}
                    authComplete={authComplete}
                  />
                  {!isVacanciesWasFiltered
                  && (
                    <div className="d-flex justify-content-center mt-3">
                      <Link
                        className="button p-3 link-to-top"
                        to="vacanciesFilters"
                        onClick={() => this.onShowMoreClicked()}
                      >
                        Показать больше
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                !isVacanciesLoadFail && (
                <div className="loader">
                  <img
                    className="loader__gif"
                    src={transparent_preloader}
                    alt="Загрузка..."
                  />
                </div>)
              )}
            </>
          </div>
        </div>
        {!authComplete && <Footer />}
      </>
    );
  }
}

AllVacanciesPage.propTypes = {
  getAllVacancies: PropTypes.func.isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  authComplete: PropTypes.bool.isRequired,
};

AllVacanciesPage.defaultProps = {
  vacancies: null,
};

const mapDispatchToProps = dispatch => ({
  getAllVacancies: () => dispatch(getAllVacancies()),
});

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;
  const { app: { authComplete } } = state;
  const { entities: { allVacancies: { vacancies } } } = state;
  const { errors } = state;
  return {
    authComplete,
    vacancies,
    location,
    errors,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllVacanciesPage);
