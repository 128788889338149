import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import renderField from './render/renderField';
import validateRestorePasswordForm from './validate/validateRestorePasswordForm';


const RestorePasswordForm = (props) => {
  const { handleSubmit, submitting } = props;
  return (
    <form
      onSubmit={handleSubmit}
    >
      <Field
        name="email"
        type="email"
        component={renderField}
        label="Почта для восстановления пароля"
      />
      <div className="auth-btns-wrap">
        <button
          type="submit"
          className="button button_color_dark-blue"
          disabled={submitting}
        >
          Восстановить пароль
        </button>
        <Link to="/login">Отмена</Link>
      </div>
    </form>
  );
};

RestorePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'restorePassword',
  validate: validateRestorePasswordForm,
})(RestorePasswordForm);
