import { createReducer } from '../utils/reducerUtils';
import {
  DESTROY_SELECT,
  INITIALIZE_SELECT,
  RESET_SELECTION,
  TOGGLE_SELECTION,
} from '../constants';


const getSelection = (state, name) => state[name];
const updateSelection = (state, name, updates) => ({
  ...state,
  [name]: updates,
});

const initializeSelect = (state, action) => ({ ...state, [action.name]: [] });

const destroySelect = (state, action) => {
  const newState = { ...state };
  delete newState[action.name];
  return newState;
};

const resetSelection = (state, action) => {
  const newState = { ...state };
  newState[action.name] = [];
  return newState;
};

const toggleSelection = (state, action) => {
  const { id, name } = action;
  const selection = getSelection(state, name);
  const selectionSet = new Set([...selection]);
  if (selectionSet.has(id)) {
    selectionSet.delete(id);
  } else {
    selectionSet.add(id);
  }
  return updateSelection(state, name, [...selectionSet]);
};

const initialState = {};

export default createReducer(initialState, {
  [TOGGLE_SELECTION]: toggleSelection,
  [INITIALIZE_SELECT]: initializeSelect,
  [DESTROY_SELECT]: destroySelect,
  [RESET_SELECTION]: resetSelection,
});
