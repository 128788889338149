import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetError } from '../actions/app';


class ErrorMessage extends Component {
  componentWillUnmount() {
    const { resetError, errorName } = this.props;
    resetError(errorName);
  }

  render() {
    const { errorMessages } = this.props;
    if (errorMessages && Object.keys(errorMessages).length) {
      const messages = Object.keys(errorMessages)
        .map((errorName) => {
          if (Array.isArray(errorMessages[errorName])) {
            return errorMessages[errorName].map(description => (
              <div key={description} className="form-error">{description}</div>
            ));
          }
          return <div key={errorName}>{errorMessages[errorName]}</div>;
        });
      return (
        <div className="error-message-container">
          {messages}
        </div>
      );
    }
    return null;
  }
}

ErrorMessage.defaultProps = {
  errorMessages: {},
};

ErrorMessage.propTypes = {
  errorName: PropTypes.string.isRequired,
  errorMessages: PropTypes.object,
  resetError: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  errorMessages: state.errors[ownProps.errorName],
});

const mapDispatchToProps = dispatch => ({
  resetError: name => dispatch(resetError(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
