import { createReducer } from '../utils/reducerUtils';
import {
  CREATE_RESUME_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  EDIT_RESUME_SUCCESS,
  GET_RESUME_SUCCESS,
} from '../constants';


const initialState = {
  resumeData: {},
};

const getResume = (state, { data }) => {
  const {
    user,
    email,
    phone,
    middle_name,
    address,
    gender,
    birth_date,
    position,
    specialization,
    salary,
    currency,
    schedule,
    experience,
    grade,
    establishments,
    key_skills: keySkills,
    about,
    languages,
    url,
    url_docx,
  } = data;

  const personal = {
    ...user,
    address,
    gender,
    birth_date,
    middle_name,
    email,
    phone,
  };

  const workInfo = {
    position,
    specialization,
    salary,
    currency,
    schedule,
    experience,
  };

  const education = {
    grade,
    establishments,
  };

  return {
    personal,
    workInfo,
    education,
    keySkills,
    languages,
    about,
    url,
    url_docx,
  };
};

const editProfile = (state, { user: { avatar } }) => ({ ...state, personal: { avatar } });

export default createReducer(initialState, {
  [GET_RESUME_SUCCESS]: getResume,
  [EDIT_RESUME_SUCCESS]: getResume,
  [CREATE_RESUME_SUCCESS]: getResume,
  [EDIT_PROFILE_SUCCESS]: editProfile,
});
