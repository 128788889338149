import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Badge, Collapse } from 'reactstrap';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  getCurrencySign,
  getGender,
  getGenderSuffix,
  getSpecialization,
  TRANSLATE_TO_RUSSIAN,
} from '../utils';
import useImageDownload from '../hooks/useImageDownload';
import { editProfile } from '../actions/app';
import ModalWindow from '../components/ModalWindow';


const ResumePage = ({ resumeData, user, editProfile }) => {
  const [downloadMenuActive, setDownloadMenuActive] = useState(false);

  const [
    fileRef,
    onFileChange,
    onFileClick,
    avatarImage,
    image,
    resetImageDownload,
    errorText,
  ] = useImageDownload();

  const {
    workInfo,
    education,
    keySkills,
    languages,
    about,
    personal,
    url,
    url_docx,
  } = resumeData;

  const modUser = {
    ...user, ...personal,
  };

  const handleDownloadMenuClick = () => {
    setDownloadMenuActive(!downloadMenuActive);
  };

  const handleSubmit = () => {
    const {
      id,
      first_name,
      last_name,
      phone,
    } = user;

    const updatedUser = new FormData();
    updatedUser.set('id', id);
    updatedUser.set('first_name', first_name);
    updatedUser.set('last_name', last_name);
    updatedUser.set('phone', phone);
    if (avatarImage) {
      updatedUser.append('avatar', avatarImage);
    }
    editProfile(id, updatedUser).then(resetImageDownload);
  };

  const {
    first_name,
    last_name,
    middle_name = null,
    birth_date = null,
    phone = null,
    email,
    address = null,
    avatar,
    gender,
  } = modUser;

  const { grade = null, establishments = [] } = education;
  const isEducation = !!(grade || establishments.length);
  const isKeySkills = !!keySkills.length;
  const isLanguages = !!languages.length;
  const isWorkInfo = Object.keys(workInfo)
    .every(elem => workInfo[elem] === '' || workInfo[elem] === null);

  const {
    workInfo: {
      position = null,
      specialization,
      salary = null,
      currency = null,
      schedule = null,
      experience = null,
    } = {},
  } = resumeData;

  const userGender = getGender(gender);
  const insertValue = value => value || 'Не указано';
  const avatarToRender = <img alt="avatar" className="resume-page-personal-avatar" src={`${image || avatar}`} />;
  const keySkillWrapper = (elem, key) => (
    window.innerWidth > 600
      ? (<h3 key={key} className="mr-1">{elem}</h3>)
      : (<h4 key={key} className="mr-1">{elem}</h4>)
  );
  return (
    <>
      <Helmet>
        <title>Резюме | Jobs Widget</title>
      </Helmet>
      <div className="page-header-container">
        <h1 className="main-page-title">Резюме</h1>
      </div>
      <div className="resume-page__button-panel d-flex">
        <Link to="/resume/edit-resume/">
          <FontAwesomeIcon
            icon="pencil-alt"
            color="rgb(53, 65, 105)"
            className="item-icon"
            title="Изменить резюме"
          />
        </Link>
        <OutsideClickHandler
          onOutsideClick={() => setDownloadMenuActive(false)}
        >
          <div className="resume-download-wrapper">
            <FontAwesomeIcon
              onClick={handleDownloadMenuClick}
              className="item-icon"
              icon="download"
              title="Загрузить файлом"
            />
            <Collapse
              isOpen={downloadMenuActive}
              className="resume-page-download-types"
            >
              <div
                className="resume-page-download-type d-flex justify-content-between"
                onClick={() => window.open(url)}
              >
                <span>Adobe Reader</span>
                <span>.pdf</span>
              </div>
              <div
                className="resume-page-download-type d-flex justify-content-between"
                onClick={() => window.open(url_docx)}
              >
                <span>Microsoft Word</span>
                <span>.doc</span>
              </div>
            </Collapse>
          </div>
        </OutsideClickHandler>
      </div>

      <div className="resume-page-wrapper content_container container">
        <div className="resume-page-personal mb-3 border-bottom resume-page-info-block">
          <div className="resume-page-section-title-wrapper d-flex mb-3">
            <h3>Персональная информация</h3>
          </div>
          <div className="resume-page-personal-wrapper">
            <div className="resume-page-personal-avatar-wrapper ml-5">
              <div className="resume-page-personal-photo-wrapper mb-2">
                <ModalWindow
                  headerText=""
                  modalButton={avatarToRender}
                  closeByAnyClick
                >
                  {avatarToRender}
                </ModalWindow>
              </div>
              <input
                className="profile-avatar-file-input"
                type="file"
                accept=".jpg, .jpeg, .png"
                ref={fileRef}
                onChange={onFileChange}
              />
              {errorText && <div>{errorText}</div>}
              {!image
                ? (<div className="resume-page-avatar-change-button" onClick={onFileClick}>Изменить аватар</div>)
                : (
                  <div className="d-flex justify-content-between">
                    <span onClick={handleSubmit} className="resume-page-avatar-change-button">
                    Обновить
                    </span>
                    <span className="resume-page-avatar-change-button" onClick={resetImageDownload}>Отмена</span>
                  </div>
                )
              }
            </div>
            <div className="resume-page-personal-info-wrapper pt-2">
              <ul className="resume-page-personal-info">
                <li>{`${first_name} ${last_name} ${middle_name || ''}`}</li>
                {(birth_date || userGender) && (
                  <li>
                    {userGender && (<span>{userGender}, </span>)}
                    {birth_date && (<span>{`${getGenderSuffix(gender)} ${birth_date}`}</span>)}
                  </li>)}
                {address && <li className="mb-3">г. {address}</li>}
                <li>{`Мобильный телефон: ${phone || 'отсутствует'}`}</li>
                <li>Почтовый адрес: {email}</li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          {!isWorkInfo && (
          <div className="resume-page-position border-bottom mb-3 resume-page-info-block">
            <div className="resume-page-section-title-wrapper d-flex mb-2">
              <h3>Желаемая должность и зарплата</h3>
            </div>
            <div className="d-flex resume-page-section-information">
              <ul className="resume-page-personal-info">
                <li>{`Должность: ${insertValue(position)}.`}</li>
                <li>{`Отрасль: ${insertValue(getSpecialization(specialization))}.`}</li>
                <li>{`Зарплата: ${insertValue(salary)}${salary ? getCurrencySign(currency) : ''}.`}</li>
                <li>{`График работы: ${TRANSLATE_TO_RUSSIAN.schedules[schedule]}.`}</li>
                <li>{`Опыт работы: ${TRANSLATE_TO_RUSSIAN.experiences[experience]}.`}</li>
              </ul>
            </div>
          </div>
          )}

          {isEducation && (
          <div className="resume-page-education border-bottom mb-3 resume-page-info-block">
            <div className="resume-page-section-title-wrapper d-flex mb-2">
              <h3>Образование</h3>
            </div>
            <div className="resume-page-section-information">
              {grade && <div className="mb-2">{TRANSLATE_TO_RUSSIAN.grade[grade]}</div>}
              {establishments.map(({
                name, faculty, specialization, year_end,
              }, index) => (
                <div key={String(index)} className="mb-2 resume-page-education-item">
                  <span>{year_end}&nbsp;</span>
                  <div className="resume-page-education-description">
                    <span className="font-weight-bold">{name}.</span>
                    {faculty && <div>{faculty}.</div>}
                    {specialization && <div> {specialization}.</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>)}

          {isLanguages && (
            <div className="resume-page-education border-bottom mb-3 resume-page-info-block">
              <div className="resume-page-section-title-wrapper d-flex mb-2">
                <h3>Знание языков</h3>
              </div>
              <div className="d-flex">
                <ul className="resume-page-personal-info resume-page-skills m-0">
                  {languages.map(({ language, level }, index) => (
                    <li
                      key={language + level + String(index)}
                    >
                      {language} - {TRANSLATE_TO_RUSSIAN.language[level]}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {isKeySkills && (
          <div className="resume-page-keySkills border-bottom mb-3 resume-page-info-block">
            <div className="resume-page-section-title-wrapper d-flex mb-2">
              <h3>Ключевые навыки</h3>
            </div>
            <div className="resume-page-section-information">
              <div className="d-flex mb-3">
                <div className="d-flex flex-wrap">
                  {keySkills.map(({ value }, index) => (
                    keySkillWrapper(
                      <Badge
                        color="secondary"
                        className="resume-page-badge"
                      >
                        {value}
                      </Badge>,
                      value + String(index),
                    )
                  ))}
                </div>
              </div>
            </div>
          </div>
          )}

          {about && (
          <div className="resume-page-about-me mb-3 resume-page-info-block">
            <div className="resume-page-section-title-wrapper d-flex mb-3">
              <h3>Обо мне</h3>
            </div>
            <div className="resume-page-section-information">
              {about}
            </div>
          </div>)}
        </div>
      </div>
      </>
  );
};

ResumePage.defaultProps = {
  resumeData: {},
};

ResumePage.propTypes = {
  user: PropTypes.object.isRequired,
  resumeData: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    resume: resumeData,
    entities: { user },
  } = state;
  return {
    user,
    resumeData,
  };
};

const mapDispatchToProps = dispatch => ({
  editProfile: (id, user) => dispatch(editProfile(id, user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResumePage);
