import { sexOptions, gradeOptions } from './resumeFormOptionsConstants';
import {
  currencyOptions, experienceOptions, scheduleOptions, specializationOptions,
} from './vacancyFormConstants';
import { getEducationalInstitutions, getFacuclties, getSpecialization } from '../../actions/app';


export const personalFields = [
  {
    name: 'last_name',
    type: 'text',
    placeholder: 'Фамилия',
    label: 'Фамилия',
    required: true,
  },

  {
    name: 'first_name',
    type: 'text',
    placeholder: 'Имя',
    label: 'Имя',
    required: true,
  },

  {
    name: 'middle_name',
    type: 'text',
    placeholder: 'Отчество',
    label: 'Отчество',
  },

  {
    name: 'address',
    type: 'text',
    placeholder: 'Город проживания',
    label: 'Город проживания',
    required: true,
  },

  {
    name: 'birth_date',
    type: 'date',
    placeholder: 'Дата рождения',
    label: 'Дата рождения',
    required: true,
  },

  {
    name: 'gender',
    type: 'select',
    label: 'Пол',
    placeholder: 'Пол',
    options: sexOptions,
  },

  {
    name: 'phone',
    type: 'tel',
    placeholder: 'Телефон',
    label: 'Телефон',
  },

  {
    name: 'email',
    type: 'email',
    placeholder: 'Электронный адрес',
    label: 'Электронный адрес',
    required: true,
  },
];

export const positionAndSalaryFields = [
  {
    name: 'position',
    type: 'text',
    placeholder: 'Должность',
    label: 'Должность',
  },

  {
    name: 'specialization',
    type: 'select',
    placeholder: 'Специализация',
    label: 'Специализация',
    options: specializationOptions,
  },

  {
    name: 'salary',
    type: 'number',
    placeholder: 'Зарплата',
    label: 'Зарплата',
  },

  {
    name: 'currency',
    type: 'select',
    placeholder: 'Валюта',
    label: 'Валюта',
    options: currencyOptions,
  },

  {
    name: 'schedule',
    type: 'select',
    options: scheduleOptions,
    placeholder: 'График работы',
    label: 'График работы',
  },

  {
    name: 'experience',
    type: 'select',
    placeholder: 'Опыт работы',
    options: experienceOptions,
    label: 'Опыт работы',
  },
];

export const educationFields = {
  grade: {
    name: 'grade',
    type: 'select',
    placeholder: 'Образование',
    options: gradeOptions,
    label: 'Образование',
  },
  establishment: [
    {
      name: 'name',
      type: 'inputWithSuggestion',
      placeholder: 'Учебное заведение',
      getSuggestions: getEducationalInstitutions,
      shouldSaveChoiceId: true,
      callRequestWhenFocus: true,
      label: 'Учебное заведение',
      required: true,
      errorClassNames: 'mr-5',
    },

    {
      name: 'faculty',
      type: 'inputWithSuggestion',
      placeholder: 'Факультет',
      getSuggestions: getFacuclties,
      label: 'Факультет',
      requestWithId: true,
    },

    {
      name: 'specialization',
      type: 'inputWithSuggestion',
      placeholder: 'Специализация',
      label: 'Специализация',
      getSuggestions: getSpecialization,
      callRequestWhenFocus: true,
    },

    {
      name: 'year_end',
      type: 'text',
      placeholder: 'Год окончания',
      label: 'Год окончания',
      required: true,
    },
  ],
};
