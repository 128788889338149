import {
  validateErrors,
  required,
  maxLength,
  phone,
} from '../validators';


const validate = (values) => {
  const validators = {
    first_name: [maxLength(30), required],
    last_name: [maxLength(30), required],
    phone: [phone],
    position: [maxLength(200)],
  };
  return validateErrors(values, validators);
};

export default validate;
