import { createReducer } from '../utils/reducerUtils';
import {
  CHECK_USER_AUTHORIZATION_SUCCESS, CREATE_RESUME_SUCCESS,
  EDIT_PROFILE_SUCCESS,
  LOGIN_USER_SUCCESS,
} from '../constants';


const updateUser = (state, updates) => ({
  ...state,
  ...updates,
});

const editProfileSuccess = (state, action) => updateUser(state, { ...action.user });

const updateAuthorizedUser = (state, action) => updateUser(state, { ...action.user });

const updateLoggedInUser = (state, action) => updateUser(state, { ...action.data.user });

const resumeCreate = (state, action) => updateUser(state, { cv: action.data.id });


const initialState = {};

const usersReducer = createReducer(initialState, {
  [EDIT_PROFILE_SUCCESS]: editProfileSuccess,
  [CHECK_USER_AUTHORIZATION_SUCCESS]: updateAuthorizedUser,
  [LOGIN_USER_SUCCESS]: updateLoggedInUser,
  [CREATE_RESUME_SUCCESS]: resumeCreate,
});

export default usersReducer;
