export const LOAD_INITIAL_DATA_SUCCESS = 'LOAD_INITIAL_DATA_SUCCESS';
export const LOAD_INITIAL_DATA_FAIL = 'LOAD_INITIAL_DATA_FAIL';

export const CHECK_USER_AUTHORIZATION_REQUEST = 'CHECK_USER_AUTHORIZATION_REQUEST';
export const CHECK_USER_AUTHORIZATION_SUCCESS = 'CHECK_USER_AUTHORIZATION_SUCCESS';
export const CHECK_USER_AUTHORIZATION_FAIL = 'CHECK_USER_AUTHORIZATION_FAIL';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const PUBLISH_VACANCY_RESTRICT = 'PUBLISH_VACANCY_RESTRICT';

export const RESTORE_PASSWORD_REQUEST = 'RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAIL = 'RESTORE_PASSWORD_FAIL';

export const CHANGE_PASSWORD_AFTER_RESTORE_REQUEST = 'CHANGE_PASSWORD_AFTER_RESTORE_REQUEST';
export const CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS = 'CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS';
export const CHANGE_PASSWORD_AFTER_RESTORE_FAIL = 'CHANGE_PASSWORD_AFTER_RESTORE_FAIL';


export const GET_RESPONSES_REQUEST = 'GET_RESPONSES_REQUEST';
export const GET_RESPONSES_SUCCESS = 'GET_RESPONSES_SUCCESS';
export const GET_RESPONSES_FAIL = 'GET_RESPONSES_FAIL';

export const CREATE_RESPONSE_REQUEST = 'CREATE_RESPONSE_REQUEST';
export const CREATE_RESPONSE_SUCCESS = 'CREATE_RESPONSE_SUCCESS';
export const CREATE_RESPONSE_FAIL = 'CREATE_RESPONSE_FAIL';

export const DELETE_HIRING_TEMPLATE_FAIL = 'DELETE_HIRING_TEMPLATE_FAIL';

export const EDIT_PROFILE_REQUEST = 'EDIT_PROFILE_REQUEST';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAIL = 'CHANGE_EMAIL_FAIL';

export const UPDATE_PAYMENT_INFO_REQUEST = 'UPDATE_PAYMENT_INFO_REQUEST';
export const UPDATE_PAYMENT_INFO_SUCCESS = 'UPDATE_PAYMENT_INFO_SUCCESS';
export const UPDATE_PAYMENT_INFO_FAIL = 'UPDATE_PAYMENT_INFO_FAIL';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAIL = 'APPLY_COUPON_FAIL';

export const RESET_API_STATUS = 'RESET_API_STATUS';

export const RESET_ERROR = 'RESET_ERROR';

export const SAVE_USER_DATA = 'SAVE_USER_DATA';

export const EDIT_USER_SETTINGS_REQUEST = 'EDIT_USER_SETTINGS_REQUEST';
export const EDIT_USER_SETTINGS_SUCCESS = 'EDIT_USER_SETTINGS_SUCCESS';
export const EDIT_USER_SETTINGS_FAIL = 'EDIT_USER_SETTINGS_FAIL';

export const TOGGLE_SELECTION = 'TOGGLE_SELECTION';
export const RESET_SELECTION = 'RESET_SELECTION';
export const INITIALIZE_SELECT = 'INITIALIZE_SELECT';
export const DESTROY_SELECT = 'DESTROY_SELECT';

export const GET_VACANCY_REQUEST = 'GET_VACANCY_REQUEST';
export const GET_VACANCY_SUCCESS = 'GET_VACANCY_SUCCESS';
export const GET_VACANCY_FAIL = 'GET_VACANCY_FAIL';

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';

export const GET_PAYMENT_INFO_REQUEST = 'GET_PAYMENT_INFO_REQUEST';
export const GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS';
export const GET_PAYMENT_INFO_FAIL = 'GET_PAYMENT_INFO_FAIL';

export const GET_ALL_VACANCIES_REQUEST = 'GET_ALL_VACANCIES_REQUEST';
export const GET_ALL_VACANCIES_SUCCESS = 'GET_ALL_VACANCIES_SUCCESS';
export const GET_ALL_VACANCIES_FAIL = 'GET_ALL_VACANCIES_FAIL';

export const ACTIVATE_ACCOUNT_REQUEST = 'ACTIVE_ACCOUNT_REQUEST';
export const ACTIVATE_ACCOUNT_SUCCESS = 'ACTIVE_ACCOUNT_SUCCESS';
export const ACTIVATE_ACCOUNT_FAIL = 'ACTIVE_ACCOUNT_FAIL';

export const CHECK_USER_HAVE_ACCOUNT_REQUEST = 'CHECK_USER_HAVE_ACCOUNT_REQUEST';
export const CHECK_USER_HAVE_ACCOUNT_SUCCESS = 'CHECK_USER_HAVE_ACCOUNT_SUCCESS';
export const CHECK_USER_HAVE_ACCOUNT_FAIL = 'CHECK_USER_HAVE_ACCOUNT_FAIL';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAIL = 'GET_CITY_FAIL';

export const GET_RESUME_REQUEST = 'GET_RESUME_REQUEST';
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
export const GET_RESUME_FAIL = 'GET_RESUME_FAIL';

export const EDIT_RESUME_REQUEST = 'EDIT_RESUME_REQUEST';
export const EDIT_RESUME_SUCCESS = 'EDIT_RESUME_SUCCESS';
export const EDIT_RESUME_FAIL = 'EDIT_RESUME_FAIL';

export const CREATE_RESUME_REQUEST = 'CREATE_RESUME_REQUEST';
export const CREATE_RESUME_SUCCESS = 'CREATE_RESUME_SUCCESS';
export const CREATE_RESUME_FAIL = 'CREATE_RESUME_FAIL';

export const WITH_LOADING_OFF = 'WITH_LOADING_OFF';
export const WITH_LOADING_ON = 'WITH_LOADING_ON';
