import {
  validateErrors,
  required,
  maxLength,
  minLength,
  equal,
  notEqual,
} from '../validators';


const validate = (values) => {
  const validators = {
    password: [maxLength(200), minLength(8), required],
    newPassword: [
      maxLength(200),
      notEqual('password', 'Новый пароль должен отличаться от предыдущего'),
      required,
    ],
    reNewPassword: [
      maxLength(200),
      equal('newPassword', 'Подтверждение пароля не совпадает'),
      required,
    ],
  };
  return validateErrors(values, validators);
};

export default validate;
