import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import FieldError from '../../components/FieldError';


const renderFileField = (
  {
    input: { value: fileValue, name, ...inputProps },
    meta: { touched, error },
    label,
    info,
    ...props
  },
) => (
  <FormGroup>
    <div className="form-file-container">
      <label htmlFor={name} className="button-add-item">{label}</label>
      <input
        className="form-file"
        id={name}
        name={name}
        {...inputProps}
        {...props}
      />
      <span className="form-file-info">
        {fileValue && fileValue.length !== 0 ? fileValue[0].name : info}
      </span>
      {(touched || (fileValue && fileValue.length !== 0)) && error && <FieldError error={error} />}
    </div>
  </FormGroup>
);

renderFileField.defaultProps = {
  info: '',
};

renderFileField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default renderFileField;
