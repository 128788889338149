import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import CreateResponseForm from '../forms/CreateResponseForm';
import { createResponse } from '../actions/responses';


const VACANCY_PAGE_NAME = 'vacanciesPage';

class CreateResponse extends React.Component {
    handleSubmit = (values) => {
      const {
        createResponse,
        user,
        authComplete,
        vacancy,
      } = this.props;

      const questionsArray = values.questions && values.questions
        .map((question) => {
          const key = Object.keys(question)[0];
          return { question: key.split('_').pop(), text: question[key] };
        });

      const formData = new FormData();

      if (authComplete) {
        formData.append('candidate', user);
      }

      Object.keys(values).forEach((key) => {
        if (key.includes('userFile_')) {
          formData.append(key.split('userFile_').pop(), values[key][0]);
        } else if (key !== 'vacancy' && key !== 'questions') {
          formData.append(key, values[key]);
        }
      });

      if (questionsArray) {
        formData.append('question_answers', JSON.stringify(questionsArray));
      }

      const { id } = vacancy;

      formData.append('vacancy', id);
      formData.append('internal_source', VACANCY_PAGE_NAME);
      createResponse(formData, authComplete);
    }

    render() {
      const {
        vacancy,
        fields,
        questions,
        authComplete,
      } = this.props;

      const notDeletedQuestions = questions.filter(question => question.deleted === null);

      return (
        <React.Fragment>
          <Helmet>
            <title>Создание отклика | Jobs Widget</title>
          </Helmet>
          <CreateResponseForm
            onSubmit={this.handleSubmit}
            vacancy={vacancy}
            fields={fields}
            questions={notDeletedQuestions}
            authComplete={authComplete}
          />
        </React.Fragment>
      );
    }
}

CreateResponse.defaultProps = {
  vacancy: {},
  fields: [],
  questions: [],
};


CreateResponse.propTypes = {
  authComplete: PropTypes.bool.isRequired,
  vacancy: PropTypes.object,
  questions: PropTypes.arrayOf(PropTypes.object),
  createResponse: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: { authComplete },
    fields,
    questions,
    app: { user },
  } = state;

  return {
    user,
    authComplete,
    fields,
    questions,
  };
};


const mapDispatchToProps = dispatch => ({
  createResponse: (response, isAllVacancyPage) => dispatch(createResponse(response, isAllVacancyPage)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateResponse));
