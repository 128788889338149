import {
  GET_RESPONSES_SUCCESS,
  CREATE_RESPONSE_SUCCESS,
} from '../constants';

import { createReducer } from '../utils/reducerUtils';


const createResponse = (state, action) => {
  const { results } = state;
  let { totalAmount = 0 } = state;
  totalAmount += 1;
  return { ...state, totalAmount, responses: [...results, action.response] };
};

const getResponses = (state, action) => action.data;

const initialState = [];

export default createReducer(initialState, {
  [GET_RESPONSES_SUCCESS]: getResponses,
  [CREATE_RESPONSE_SUCCESS]: createResponse,
});
