import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const KeySkillItem = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { onDelete, skillName } = props;


  const onKeySkillItemDelete = () => {
    setIsDeleting(true);
    onDelete();
  };

  return (
    <div
      className={
        `key-skill-item ${isDeleting
          ? 'key-skill-delete-animation'
          : ''}`
      }
    >
      <p className="mr-2 key-skill-item-desc">{skillName}</p>
      <span className="key-skill-delete-wrapper" onClick={onKeySkillItemDelete}>
        <FontAwesomeIcon
          className="item-icon key-skill-delete"
          icon="trash-alt"
        />
      </span>
    </div>
  );
};

KeySkillItem.propTypes = {
  skillName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default KeySkillItem;
