import React from 'react';
import { Container } from 'reactstrap';
import '../styles/layout/PublicHeader.css';
import appConfig from '../configs/appConfig';


const PublicHeader = () => (
  <header className="header">
    <Container className="d-flex justify-content-between header-wrapper">
      <a href="https://jobswidget.me" alt="jobswidget.me" className="logo-wrapper">
        <div className="menu-logo-public navigation-logo">{' '}</div>
      </a>
      <nav className="header-nav">
        <ul className="header-nav-menu p-0">
          <li className="header_nav-menu-item mr-2">
            <a href={`${appConfig.APP_SITE_URL}/signup`} className="header_nav-menu-item-link">Регистрация</a>
          </li>
          <li className="header_nav-menu-item">
            <a href={`${appConfig.APP_SITE_URL}/login`} className="header_nav-menu-item-link">Войти</a>
          </li>
        </ul>
      </nav>
    </Container>
  </header>
);


export default PublicHeader;
