import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import history from '../routes/history';


const LicenseAgreement = () => (
  <div className="agreement-page">
    <Helmet>
      <title>Лицензионное соглашение | Jobs Widget</title>
    </Helmet>
    <Link to="#" className="return-link" onClick={() => history.goBack()}>
      <span className="return-link__arrow" />
      <span className="return-link__text">Вернуться назад</span>
    </Link>
    <p className="agreement-text-top">
      Перед использованием сервиса ознакомьтесь с данным
      <br />
      Лицензионным соглашением.
    </p>
    <div className="agreement-section">
      <h3 className="agreement-title">1. Предмет лицензирования</h3>
      <div>
        <p className="agreement-text">
          1.1. Настоящее лицензионное соглашение (далее «Соглашение») заключено между компанией ООО «Технологии
          бизнеса»,
          предоставляющей неисключительные права на использование&nbsp;программы для ЭВМ JobWidget (далее «Сервис»),
          являющейся правообладателем исключительных прав на Сервис и именуемой в дальнейшем «Лицензиар», и
          пользователем
          Сервиса, именуемым далее «Лицензиат».
        </p>
        <p className="agreement-text">
          1.2. Сервис обеспечивает возможность размещения на сайте Лицензиата
          виджета с вакансиями, управление списком вакансий через отдельный интерфейс пользователя предоставляемый
          Лицензиату в составе сервиса. Доступ обеспечивается посредством регистрации Лицензиата на
          сайте &nbsp;Лицензиара, дальнейшей его авторизации при помощи логина и пароля к учётной
          записи &nbsp;Лицензиата,
          а также HTML-кода, размещаемого на сайте Лицензиата в сети Интернет. Подробное описание Сервиса опубликовано
          на
          официальном веб-сайте Лицензиара, доступном в сети Интернет по адресу&nbsp;
          <a href="https://jobswidget.me/" rel="nofollow">https://jobswidget.me/</a>
          &nbsp;(далее именуемом «Веб-сайт»).
        </p>
        <p className="agreement-text">
          1.3. Соглашение регулирует условия передачи и объём прав использования &nbsp;Сервиса
          Лицензиатом. В тех пунктах, которые явно не регулируются Соглашением, интересы Лицензиара и Лицензиата
          охраняются в соответствии с законодательством Российской Федерации об авторском праве и информации, а также с
          международными соглашениями.
        </p>
        <p className="agreement-text">
          1.4. Регистрируясь в качестве пользователя Сервиса и/или начиная его
          использование любым способом, Вы выражаете свое полное и безоговорочное согласие со всеми условиями
          Соглашения.
        </p>
      </div>
    </div>
    <div className="agreement-section">
      <h3 className="agreement-title">2. Права и обязанности сторон</h3>
      <div>
        <p className="agreement-text">
          2.1. Использование Сервиса разрешается только на условиях настоящего Соглашения. Если Лицензиат не принимает
          условий Соглашения в полном объеме, он не имеет права использовать Сервис в каких-либо целях. Использование
          Сервиса с нарушением (невыполнением) какого-либо из условий Соглашения запрещено.
        </p>
        <p className="agreement-text">
          2.2. Лицензиар обязуется обеспечивать работу Сервиса в рамках описанной в документации функциональности,
          предоставлять Лицензиату техническую поддержку.
        </p>
        <p className="agreement-text">
          2.3. Лицензиат обязуется своевременно и в полном объёме оплачивать Лицензиару вознаграждение за передачу прав
          использования Сервиса. При невыполнении своих обязательств Лицензиат утрачивает свои права, установленные
          Соглашением. Размеры вознаграждения опубликованы на Веб-сайте. Их перечень и условия не являются
          фиксированными
          и могут изменяться Лицензиаром в одностороннем порядке.
        </p>
        <p className="agreement-text">
          2.4. Лицензиар вправе отказать любому физическому и юридическому лицу в удовлетворении заявки на передачу прав
          использования Сервиса без объяснения причин.
        </p>
        <p className="agreement-text">
          2.5. Лицензиар и Лицензиат вправе отказаться от продления договора о передаче прав использования Сервиса в
          одностороннем порядке без объяснения причин.
        </p>
        <p className="agreement-text">
          2.6 Лицензиат соглашается принимать письма на все предоставляемые ящики электронной почты содержащие как
          служебную
          информацию связанную с работой системы, также информационные и новостные рассылки. Об отказе от информационных
          рассылок необходимо сообщить дополнительно по адресу службы поддержки support@jobswidget.me.
        </p>
        <p className="agreement-text">
          2.7 В случае прекращения использования Сервиса до окончания оплаченного периода (и во всех других случаях)
          ранее
          произведенные оплаты не возвращаются.
        </p>
      </div>
    </div>
    <div className="agreement-section">
      <h3 className="agreement-title">3. Права на сервис</h3>
      <div>
        <p className="agreement-text">
          3.1. Исключительные права на Сервис и программу для ЭВМ JobsWidget принадлежат Лицензиару.
        </p>
        <p className="agreement-text">
          3.2. Лицензиату не передаются никакие имущественные права на Сервис и связанные с ним материалы.
        </p>
        <p className="agreement-text">
          3.3. Лицензиар предоставляет Лицензиату неисключительное непередаваемое право (простая лицензия) использовать
          Сервис на условиях, указанных в п. 4. Соглашения.
        </p>
      </div>
    </div>
    <div className="agreement-section">
      <h3 className="agreement-title">4. Условия использования</h3>
      <div>
        <p className="agreement-text">
          Лицензиат имеет право использовать Сервис на следующих условиях:
        </p>
        <p className="agreement-text">
          4.1. Разрешается применять Сервис только по прямому функциональному назначению, описанному в документации.
        </p>
        <p className="agreement-text">
          4.2. Разрешается использовать Сервис через подключение по сети Интернет с неограниченного числа компьютеров и
          адресов IP.
        </p>
        <p className="agreement-text">
          4.3. Запрещается вносить изменения в HTML-код, предоставляемый Лицензиаром для размещения на сайте Лицензиата
          для
          подключения к Сервису.
        </p>
        <p className="agreement-text">
          4.4. Запрещается удалять и скрывать наименование компании Лицензиара и Сервиса, изменять и/или удалять
          обозначение
          авторских прав Лицензиара.
        </p>
        <p className="agreement-text">
          4.5. Лицензиат не имеет права изменять, декомпилировать, дизассемблировать, дешифровать и производить иные
          действия с объектным кодом Сервиса, имеющие целью получение информации о реализации алгоритмов, используемых в
          Сервисе, создавать производные программные продукты с использованием Сервиса, а также осуществлять или
          разрешать
          осуществлять иное использование Сервиса без письменного согласия Лицензиара.
        </p>
        <p className="agreement-text">
          4.6. Лицензиат не имеет права передавать право использования Сервиса другим лицам без письменного согласия
          Лицензиара.
        </p>
      </div>
    </div>
    <div className="agreement-section">
      <h3 className="agreement-title">5. Ответственность сторон</h3>
      <div>
        <p className="agreement-text">
          5.1. Сервис предоставляется на условиях «как есть» («as is»). Лицензиар не предоставляет никаких гарантий в
          отношении безошибочной и бесперебойной работы Сервиса, соответствия Сервиса конкретным целям Лицензиата, а
          также
          не предоставляет никаких иных гарантий, прямо не указанных в настоящем Соглашении.
        </p>
        <p className="agreement-text">
          5.2. В максимальной степени, допустимой действующим законодательством, Лицензиар, равно как и его партнёры, не
          несёт никакой ответственности за какие-либо прямые или косвенные последствия какого-либо использования или
          невозможности использования Сервиса и/или ущерб, причиненный Лицензиату и/или третьим лицам в результате
          какого-либо использования или неиспользования Сервиса, в том числе из-за возможных ошибок или сбоев в работе
          Сервиса.
        </p>
      </div>
    </div>
  </div>
);

export default LicenseAgreement;
