import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


const Breadcrumbs = (props) => {
  const { breadcrumbs } = props;
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(({ title, link }) => (link
        ? (
          <React.Fragment key={title}>
            <Link className="breadcrumbs__link" to={link}>{title}</Link>
            <span className="breadcrumbs__divider"> - </span>
          </React.Fragment>
        )
        : <span className="breadcrumbs__item" key={title}>{title}</span>
      ))}
    </div>
  );
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

export default Breadcrumbs;
