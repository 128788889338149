import React from 'react';
import PropTypes from 'prop-types';

import EducationFieldSet from '../../components/EducationFieldSet';
import useUniqueKeys from '../../hooks/useUniqueKeys';


const renderEducationFieldSet = ({
  fields, changeFormValue, validateResume,
}) => {
  const [onDelete, modFields, setWasDeleted] = useUniqueKeys(fields);

  const handleFieldDelete = (id, index) => {
    fields.remove(index);
    onDelete(id);
  };

  const handleFieldAdd = () => {
    fields.push({});
    setWasDeleted(false);
  };

  return (
    <>
      { modFields.map(({ id }, index) => (
        <EducationFieldSet
          key={id}
          member={`establishments[${index}]`}
          index={index}
          validateResume={validateResume}
          changeFormValue={changeFormValue}
          onDelete={() => handleFieldDelete(id, index)}
        />))}
      <button
        type="button"
        className="button button_plus"
        onClick={handleFieldAdd}
      >
        <span className="button-icon">+</span>
        <span className="button-text">Добавить учебное заведение</span>
      </button>
    </>
  );
};

renderEducationFieldSet.defaultProps = {
  fields: {},
  changeFormValue: () => {},
};

renderEducationFieldSet.propTypes = {
  fields: PropTypes.object,
  changeFormValue: PropTypes.func,
  validateResume: PropTypes.func.isRequired,
};

export default renderEducationFieldSet;
