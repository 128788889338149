export const getGeoQueryOptions = () => {
  const token = '39919afc8446fb6af07618d286cbfcbd76a591d3';
  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  };

  return options;
};

export const getGeoUrl = () => 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address';
