import React from 'react';
import PropTypes from 'prop-types';
import { Field, isDirty, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';

import renderField from './render/renderField';
import validateProfileForm from './validate/validateProfileForm';
import StatusMessage from '../containers/StatusMessage';
import normalizePhone from './normalize';


const ProfileForm = (props) => {
  const { handleSubmit, dirty, imageNotSaved } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Prompt
        when={dirty || imageNotSaved}
        message="Изменения данных профиля не сохранены. Вы уверены, что хотите покинуть страницу?"
      />
      <Field
        name="last_name"
        type="text"
        component={renderField}
        label="Фамилия"
      />
      <Field
        name="first_name"
        type="text"
        component={renderField}
        label="Имя"
      />
      <Field
        name="phone"
        type="phone"
        component={renderField}
        label="Телефон"
        placeholder="+7 (999) 123-45-67"
        normalize={normalizePhone}
      />
      <button
        type="submit"
        className="button"
      >
        Сохранить изменения
      </button>
      <StatusMessage
        status="editProfileStatus"
        successMessage="Изменения успешно сохранены"
        errorMessage="Ошибка, изменения не сохранены"
      />
    </form>
  );
};

ProfileForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  imageNotSaved: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const dirty = isDirty('changeProfile')(state);
  return {
    dirty,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'changeProfile',
    validate: validateProfileForm,
  }),
)(ProfileForm);
