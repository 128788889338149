export const updateObject = (oldObject, newValues) => ({ ...oldObject, ...newValues });

export const createReducer = (initialState, handlers) => function reducer(state = initialState, action) {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};

export const createApiReducer = (initialState, apiHandlers, handlers) => (
  function reducer(state = initialState, action) {
    for (let i = 0; i < apiHandlers.length; i += 1) {
      const { name, types } = apiHandlers[i];
      const [requestType, successType, failType] = types;
      if (action.type === requestType) {
        return { ...state, [name]: 'request', [`${name}-descripion`]: { ...action } };
      }
      if (action.type === successType) {
        return { ...state, [name]: 'success', [`${name}-descripion`]: { ...action } };
      }
      if (action.type === failType) {
        return { ...state, [name]: 'fail', [`${name}-descripion`]: { ...action } };
      }
    }
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  });

export const createErrorsReducer = (initialState, errorHandlers, handlers) => (
  function reducer(state = initialState, action) {
    for (let i = 0; i < errorHandlers.length; i += 1) {
      const { name, type } = errorHandlers[i];
      if (action.type === type) {
        return { ...state, [name]: action.error };
      }
    }
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  });
