import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';

import ResponsesList from '../components/ResponsesList';
import ResponsesPageFilterContainer from '../components/ResponsesPageFilterContainer';
import { loadResponses } from '../actions/responses';
import history from '../routes/history';
import Breadcrumbs from '../components/Breadcrumbs';
import NotFoundPage from '../components/NotFoundPage';
import { resetSelection } from '../actions/selection';


class ResponsesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
  }

  handleFilterChange = (searchParams) => {
    history.push({ pathname: '/responses', search: `?${searchParams}` });
  }

  responsesFilter = () => {
    const {
      responses,
      vacancyId,
      searchParams: {
        viewed,
        unViewed,
        hasCv,
        noCv,
      },
    } = this.props;
    return responses.filter((response) => {
      const { fields, _fields } = response;
      const file = _fields.find(({ type, name }) => type === 'file' && fields[name]);
      return (!vacancyId || response.vacancy === vacancyId)
      && ((response.viewed && viewed) || (!response.viewed && unViewed))
      && ((hasCv && (response.fields.cv || file)) || (noCv && !(response.fields.cv || file)));
    });
  };

  render() {
    const {
      responses,
      vacancies,
      vacancyId,
      location,
      searchParams,
      resetSelection,
      deleteResponse,
      markResponseAsViewed,
    } = this.props;

    const { showFilter } = this.state;
    if (vacancyId && !vacancies.some(vacancy => vacancy.id === vacancyId)) {
      return <NotFoundPage />;
    }

    const filteredResponses = this.responsesFilter();

    const filteredVacancies = vacancyId ? vacancies.filter(vacancy => vacancy.id === vacancyId) : vacancies;

    const breadcrumbs = [
      { title: 'Отклики', link: '/responses' },
    ];

    const title = 'Отклики | Jobs Widget';

    return (
      <div className="responses-wrap section-grey">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div className="page-header-container">
          <h1 className="main-page-title">Отклики</h1>
          <div
            className="responses-filter-open-box"
            onClick={() => {
              this.setState({ showFilter: !showFilter });
            }}
          >
            <FontAwesomeIcon
              className="item-icon responses-filter-icon"
              icon="filter"
            />
            Показать фильтры
            { showFilter ? (
              <FontAwesomeIcon
                className="item-icon responses-filter-chevron-icon"
                icon="chevron-up"
              />
            ) : (
              <FontAwesomeIcon
                className="item-icon responses-filter-chevron-icon"
                icon="chevron-down"
              />
            )}
          </div>
        </div>
        <Collapse
          isOpen={showFilter}
        >
          <ResponsesPageFilterContainer
            responses={responses}
            vacancies={vacancies}
            handleFilterChange={this.handleFilterChange}
            vacancyId={vacancyId}
            searchParams={searchParams}
          />
        </Collapse>
        <ResponsesList
          items={filteredResponses}
          vacancies={filteredVacancies}
          location={location}
          resetSelection={resetSelection}
          deleteResponse={deleteResponse}
          markResponseAsViewed={markResponseAsViewed}
        />
      </div>
    );
  }
}

ResponsesPage.defaultProps = {
  vacancies: [],
};

ResponsesPage.propTypes = {
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  vacancies: PropTypes.arrayOf(PropTypes.object),
  vacancyId: PropTypes.number.isRequired,
  searchParams: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  resetSelection: PropTypes.func.isRequired,
  deleteResponse: PropTypes.func.isRequired,
  markResponseAsViewed: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { responses: { responses = [] } } = state;

  const { location } = ownProps;
  const locationSearch = new URLSearchParams(location.search);
  const searchParams = {};

  searchParams.viewed = locationSearch.get('viewed') === null || locationSearch.get('viewed') === 'true';
  searchParams.unViewed = locationSearch.get('un_viewed') === null || locationSearch.get('un_viewed') === 'true';
  searchParams.hasCv = locationSearch.get('has_cv') === null || locationSearch.get('has_cv') === 'true';
  searchParams.noCv = locationSearch.get('no_cv') === null || locationSearch.get('no_cv') === 'true';
  searchParams.date_from = locationSearch.get('date_from') || null;
  searchParams.date_to = locationSearch.get('date_to') || null;

  return {
    responses,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  loadResponses: () => dispatch(loadResponses()),
  resetSelection: name => dispatch(resetSelection(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponsesPage);
