import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import dateformat from 'dateformat';

import { formatPhone } from '../utils';


const ResponseItem = (props) => {
  const {
    response:
      {
        id,
        title,
        created,
        status,
        fields,
        _fields,
        viewed,
      },
    isSelected,
    toggleSelection,
  } = props;

  const {
    first_name,
    last_name,
    email,
    phone,
    cv,
  } = fields;

  const name = last_name ? `${first_name} ${last_name}` : first_name;

  const getFileUrl = () => {
    const file = _fields.find(({ type, name }) => type === 'file' && fields[name]);
    if (file) {
      return fields[file.name];
    }
    return null;
  };
  const fileUrl = cv || getFileUrl();

  const handleSelect = () => {
    toggleSelection(id);
  };

  return (
    <div className={`content-container__item scroll-position-${id}`}>
      <div className="response-item-title">
        {!viewed && <div className="response-item-new-label">Новый</div>}
        <div className="response-item-position">
          {title}
        </div>
        <div className="response-item-name">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={handleSelect}
              className="checkbox-hidden"
            />
            <span className="checkbox-mark" />
          </label>
          <Link className="response-item-name-link" to={`/responses/${id}`}>
            {name}
          </Link>
        </div>
      </div>
      <div className="response-item-description-list">
        {phone && (
          <div className="response-item-description-elem">
            <a href={`tel:${formatPhone(phone)}`} className="response-item-description-elem">{formatPhone(phone)}</a>
          </div>
        )}
        {email && (
          <div className="response-item-description-elem">
            <a href={`mailto:${email}`} className="response-item-description-elem">{email}</a>
          </div>
        )}
        <div className="response-item-description-elem">{dateformat(created.toString(), 'dd.mm.yyyy')}</div>
        <div className="response-item-description-elem">
          {fileUrl ? (
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="light-green"
            >
              Резюме
            </a>
          ) : (
            'Нет резюме'
          )}
        </div>
        {status && <div className="response-item-description-elem dark-blue">{status}</div>}
      </div>
    </div>
  );
};

ResponseItem.propTypes = {
  response: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleSelection: PropTypes.func.isRequired,
};

export default withRouter(ResponseItem);
