import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './render/renderField';
import normalizePhone from './normalize';
import { required } from './validators';


const ResponseForm = (props) => {
  const getFields = (response) => {
    const result = [];
    const { _fields: fields } = response;
    Object
      .keys(response.fields)
      .forEach((key) => {
        const field = fields.find(field => field.name === key) || {};
        if (field.type !== 'file') {
          result.push((
            <Field
              key={key}
              type={field.type}
              name={key}
              component={renderField}
              label={field.placeholder}
              validate={field.required && required}
              rows="10"
              normalize={key === 'phone' && normalizePhone}
            />
          ));
        }
      });
    return result;
  };

  const {
    handleSubmit,
    response,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      {getFields(response)}
    </form>
  );
};

ResponseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  response: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'response',
})(ResponseForm);
