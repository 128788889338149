import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const TooltipComponent = (props) => {
  const { text, name, className } = props;
  return (
    <div className="tooltip-custom">
      <FontAwesomeIcon
        id={`tooltip-${name}`}
        className={`item-icon tooltip-custom__icon ${className}`}
        icon="question-circle"
      />
      <UncontrolledTooltip
        autohide={false}
        placement="top"
        target={`tooltip-${name}`}
        innerClassName="tooltip-custom__window"
        arrowClassName="tooltip-custom__arrow"
      >
        {text}
      </UncontrolledTooltip>
    </div>
  );
};

TooltipComponent.defaultProps = {
  className: '',
};

TooltipComponent.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TooltipComponent;
