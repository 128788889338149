import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getSalaryText, stripHtml } from '../utils';
import appConfig from '../configs/appConfig';


class AllVacanciesItem extends Component {
  constructor(props) {
    super(props);
    this.x = createRef();
    this.state = { ellipsis: false };
  }

  componentDidMount() {
    if (this.x.current.clientHeight > 60) {
      this.setState({ ellipsis: true });
    }
  }

  render() {
    const {
      vacancy: {
        city,
        title,
        team_title,
        currency,
        id,
        publish_date,
        salary_from,
        description,
        salary_to,
        logo,
        is_remote,
      },
      authComplete,
    } = this.props;

    const {
      ellipsis,
    } = this.state;

    const vacancyPublishDate = new Date(publish_date);
    const date = vacancyPublishDate.toLocaleString('ru', { day: 'numeric', month: 'long' });
    const link = authComplete
      ? (
        <Link target="_blank" to={`/all-vacancies/${id}`}>
          <h2 className="allvacancy-item-title vacancy-item-title">{`${title}`}</h2>
        </Link>
      ) : (
        <a target="_blank" rel="noopener noreferrer" href={`${appConfig.JOB_SITE_URL}/vacancies/${id}`}>
          <h2 className="allvacancy-item-title vacancy-item-title">{`${title}`}</h2>
        </a>
      );

    return (
      <div className="col-12 allvacancy-item-wrapper p-4 d-flex">
        <div className="allvacancy-item-info-wrapper">
          <div className="allvacancy-item-title-wrapper">
            {link}
            <span className="allvacancy-item-salary">
              {getSalaryText(salary_from, salary_to, currency)}
            </span>
          </div>
          <div className="mb-2">
            {city && (<p className="mb-1 text-color-dark-blue">{city}</p>)}
            <p className="m-0 text-color-dark-blue">{`
              ${team_title},
              ${is_remote ? 'Удалённая работа' : 'Работа в офисе'}`}
            </p>
          </div>
          <div className="allvacancy-item-desc-wrapper d-flex">
            <div className="allvacancy-item-desc text-color-dark-blue" ref={this.x}>
              <p>{stripHtml(description)}</p>
              {ellipsis
              && (
              <Link target="_blank" to={`/all-vacancies/${id}`} className="allvacancy-item-desc-ellipsis ">
                    &nbsp;
              </Link>
              )
            }
            </div>
            {logo && (
              <div className="allvacancy-picture-wrapper">
                <img
                  className="allvacancy-picture"
                  src={logo}
                  alt="Логотип вакансии"
                />
              </div>)}
          </div>
          <div className="allvacancy-item-date text-color-dark-blue col-12 p-0 mt-2">
            {date}
          </div>
        </div>
      </div>
    );
  }
}

AllVacanciesItem.propTypes = {
  vacancy: PropTypes.object.isRequired,
  authComplete: PropTypes.bool.isRequired,
};

export default AllVacanciesItem;
