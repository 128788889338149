import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import KeySkillItems from '../../components/KeySkillItems';
import { getKeySkillSuggestions } from '../../actions/app';
import FieldError from '../../components/FieldError';
import InputSuggestion from '../../components/InputSuggestion';


const MAX_KEY_SKILL_LENGTH = 64;

const renderSkillKeys = (props) => {
  const {
    skills,
    changeformvalue: changeFormValue,
    keySkillValue,
    fields,
  } = props;

  const [currentTimerId, setCurrentTimerId] = useState(null);
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [keySkillErrors, setKeySkillErrors] = useState({
    alreadyExist: false,
    lengthOverSize: false,
  });


  const onKeySkillAdd = () => {
    if (skills.map(({ value }) => value).includes(keySkillValue)) {
      setKeySkillErrors({ alreadyExist: true, lengthOversize: false });
    } else if (keySkillValue.length > MAX_KEY_SKILL_LENGTH) {
      setKeySkillErrors({ alreadyExist: false, lengthOversize: true });
    } else {
      fields.push({ value: keySkillValue });
      changeFormValue('key_skill', '');
      setKeySkillErrors({ alreadyExist: false, lengthOversize: false });
    }
  };

  const onKeySkillDelete = (index) => {
    setSkillsToDelete(prevValue => [...prevValue, index]);

    if (currentTimerId) {
      clearTimeout(currentTimerId);
    }

    const deleteTimer = setTimeout(() => {
      const modSkillsToDelete = [...skillsToDelete, index];
      modSkillsToDelete.sort((x, y) => y - x);
      modSkillsToDelete.forEach((index) => {
        fields.remove(index);
      });
      setSkillsToDelete([]);
    }, 800);

    setCurrentTimerId(deleteTimer);
  };

  return (
    <>
      <div className="mb-5">
        <KeySkillItems skills={skills} onKeySkillDelete={onKeySkillDelete} />
        <div className="key-skill-input-wrapper">
          <InputSuggestion
            name="key_skill"
            type="text"
            label="Навык"
            placeholder="Например 'пунктуальность'"
            value={keySkillValue}
            changeFormValue={changeFormValue}
            getSuggestions={getKeySkillSuggestions}
            classNames="key-skill-input"
          />
          <button
            type="button"
            disabled={!keySkillValue}
            className="button button_plus key-skill-add-button"
            onClick={onKeySkillAdd}
          >
            <span className="button-icon">+</span>
            <span className="button-text">Добавить навык</span>
          </button>
          {keySkillErrors.alreadyExist && <FieldError error="Данный навык уже был добавлен" />}
          {keySkillErrors.lengthOversize && <FieldError error="Навык превышает длинну в 64 символа" />}
        </div>
      </div>
    </>
  );
};

renderSkillKeys.defaultProps = {
  skills: [],
  fields: {},
  keySkillValue: '',
  changeformvalue: () => {},
};

renderSkillKeys.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object),
  keySkillValue: PropTypes.string,
  fields: PropTypes.object,
  changeformvalue: PropTypes.func,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('editResume');
  const keySkillValue = selector(state, 'key_skill');
  const skills = selector(state, 'keySkills');
  return {
    keySkillValue,
    skills,
  };
};

export default connect(mapStateToProps, null)(renderSkillKeys);
