import {
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
} from '../constants';
import axios from '../api';


export const changePassword = (currentPassword, newPassword, reNewPassword) => ({
  types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
  callApi: () => axios.post('/auth/password/', {
    current_password: currentPassword,
    new_password: newPassword,
    re_new_password: reNewPassword,
  }),
});

export const changeEmail = email => ({
  types: [CHANGE_EMAIL_REQUEST, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAIL],
  callApi: () => axios.post('/auth/email/change/', { email }),
});
