import React, { useEffect, useState } from 'react';
import { Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import renderField from '../forms/render/renderField';
import renderSelectField from '../forms/render/renderSelectField';
import { educationFields } from '../forms/constants/resumeFormFieldConstants';
import renderFileField from '../forms/render/renderFileField';
import { required } from '../forms/validators';
import InputSuggestion from './InputSuggestion';


const EducationFieldSet = ({
  onDelete,
  member,
  establishments,
  index,
  changeFormValue,
  validateResume,
}) => {
  const [deleteAnimation, setDeleteAnimation] = useState(false);
  const [addEducationAnimation, setAddEducationAnimation] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const deleteAnimationTimer = setAddEducationAnimation(false);
      return () => clearTimeout(deleteAnimationTimer);
    }, 1000);
  }, []);

  const handleDelete = () => {
    setDeleteAnimation(true);
    onDelete();
  };

  const getRenderType = (type) => {
    switch (type) {
      case 'file': return renderFileField;
      case 'select': return renderSelectField;
      default: return renderField;
    }
  };

  return (
    <div
      className={`
      resume-from-education-field-set
      ${deleteAnimation ? 'education-delete-animation' : ''}
      ${addEducationAnimation ? 'education-add-animation' : ''}
    `}
    >
      <button
        type="button"
        title="удалить заведение"
        className="resume-from-education-delete"
        onClick={handleDelete}
      >
          Удалить
      </button>
      {educationFields.establishment.map(field => (
        <div key={`${member}${field.name}`} className="education-field-wrapper">
          { field.type !== 'inputWithSuggestion'
            ? (
              <Field
                name={`${member}${field.name}`}
                label={field.label}
                placeholder={field.placeholder}
                value={(establishments.length > index) && establishments[index].name}
                validate={field.required ? [validateResume(field.type), required]
                  : validateResume(field.type)}
                type={field.type}
                component={getRenderType(field.type)}
                options={field.options}
              />
            ) : (
              <InputSuggestion
                {...field}
                index={index}
                name={`${member}${field.name}`}
                value={(establishments.length > index) ? establishments[index][field.name] : ''}
                validate={field.required ? [validateResume(field.type), required]
                  : validateResume(field.type)}
                changeFormValue={changeFormValue}
                idForRequest={establishments[index] && establishments[index].university_id}
                fieldSetName="education"
              />)}
        </div>))}
    </div>
  );
};

EducationFieldSet.defaultProps = {
  changeFormValue: () => {},
};

EducationFieldSet.propTypes = {
  onDelete: PropTypes.func.isRequired,
  member: PropTypes.string.isRequired,
  establishments: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeFormValue: PropTypes.func,
  index: PropTypes.number.isRequired,
  validateResume: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('editResume');
  const establishments = selector(state, 'education.establishments');
  return {
    establishments,
  };
};

export default connect(mapStateToProps, null)(EducationFieldSet);
