import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app';
import responses from './responses';
import entities from './entities';
import api from './api';
import payment from './payment';
import errors from './errors';
import selection from './selection';
import resume from './resume';
import { LOGOUT_USER_SUCCESS } from '../constants';


const appReducer = combineReducers({
  app,
  entities,
  responses,
  resume,
  api,
  payment,
  errors,
  selection,
  form: formReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER_SUCCESS) {
    return appReducer(
      {
        app: {
          authInProgress: false,
          authComplete: false,
          dataLoaded: false,
        },
      },
      action,
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
