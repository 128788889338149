import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import ConfirmModal from '../components/ConfirmModal';


import ResponseForm from '../forms/ResponseForm';
import StatusMessage from './StatusMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import ResponseData from '../components/ResponseData';


class ResponseReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
    };
  }

  render() {
    const { isEditable } = this.state;
    const {
      response,
      submitForm,
    } = this.props;

    const { first_name, last_name } = response.fields;
    const name = last_name ? `${first_name} ${last_name}` : first_name;

    const breadcrumbs = [
      { title: 'Отклики', link: '/responses' },
      { title: name, link: '' },
    ];
    const title = `${name} | Jobs Widget`;

    return (
      <div className="response-page">
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row className="justify-content-md-between">
          <Col md="6" lg="5" className="response-left">
            <div className="response-left__edit-icon">
              <FontAwesomeIcon
                className="item-icon"
                icon="pencil-alt"
                onClick={this.toggleIsEditable}
              />
            </div>
            {isEditable
              ? (
                <React.Fragment>
                  <ResponseForm
                    onSubmit={this.handleSubmit}
                    initialValues={response.fields}
                    response={response}
                  />
                  <div className="buttons-container">
                    <button
                      className="button"
                      type="button"
                      onClick={submitForm}
                    >
                      Сохранить
                    </button>
                    <button
                      className="button button_color_dark-blue"
                      type="button"
                      onClick={this.toggleIsEditable}
                    >
                      Отменить
                    </button>
                  </div>
                </React.Fragment>
              )
              : (
                <div className="applicant">
                  <ResponseData response={response} />
                </div>
              )
            }
            {/* <ResponseQuestionsList questions={questions} response={response} /> */}
            <div className="buttons-container">
              <ConfirmModal
                type="button"
                headerText="Удалить отклик?"
                buttonText="Удалить отклик"
                modalAction={this.handleDelete}
              />
            </div>
            <StatusMessage
              status="editResponseStatus"
              errorMessage="Ошибка при сохранении изменений"
            />
            <StatusMessage
              status="deleteResponseStatus"
              errorMessage="Ошибка при удалении отклика"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ResponseReview.propTypes = {
  submitForm: PropTypes.func.isRequired,
  response: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const {
    responses,
    questions,
  } = state;
  const responseId = +ownProps.match.params.responseId;
  const response = responses.find(response => response.id === responseId);

  if (!response) {
    return {
      response,
    };
  }

  return {
    response,
    comments: response.comments,
    questions,
  };
};

export default withRouter(
  connect(mapStateToProps, null)(ResponseReview),
);
