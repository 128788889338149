import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import RestorePasswordForm from '../forms/RestorePasswordForm';
import { restorePassword } from '../actions/app';
import ErrorMessage from '../components/ErrorMessage';


const PasswordRestore = (props) => {
  const { restorePassword } = props;
  return (
    <div className="restore-page full-height-wrap section-grey">
      <Helmet>
        <title>Восстановление пароля | Jobs Widget</title>
      </Helmet>
      <h2 className="auth-title">Восстановление пароля</h2>
      <div className="auth-content">
        <RestorePasswordForm
          onSubmit={values => restorePassword(values.email)}
        />
        <ErrorMessage errorName="restorePasswordError" />
      </div>
    </div>
  );
};

PasswordRestore.propTypes = {
  restorePassword: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  restorePassword: email => dispatch(restorePassword(email)),
});

export default connect(null, mapDispatchToProps)(PasswordRestore);
