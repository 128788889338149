import demo from './demo';
import local from './local';
import dev from './dev';
import staging from './staging';
import prod from './prod';


const setConfig = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return prod;
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    return staging;
  }
  if (process.env.REACT_APP_ENV === 'local') {
    return local;
  }
  if (process.env.REACT_APP_ENV === 'demo') {
    return demo;
  }
  return dev;
};

const appConfig = setConfig();
export { appConfig as default };
