import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import appConfig from '../configs/appConfig';

import { loginUser } from '../actions/app';
import LoginForm from '../forms/LoginForm';


class Login extends React.Component {
  handleSubmit = ({ email, password }) => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const vacancyId = searchParams.get('vacancy') || null;
    const { loginUser } = this.props;
    loginUser(email, password, vacancyId);
  };

  render() {
    const appSiteUrl = appConfig.APP_SITE_URL;
    return (
      <div className="full-height-wrap login-page section-grey">
        <Helmet>
          <title>Войти | Jobs Widget</title>
        </Helmet>
        <h2 className="auth-title">Вход соискателя</h2>
        <div className="auth-content">
          <LoginForm
            onSubmit={this.handleSubmit}
          />
          <hr />
          <div>
            <h3 className="text-center">Регистрация</h3>
            <div className="signUp-type-change-wrapper mt-4">
              <Link to="/signUp" className="button signUp-type-change">Соискателя</Link>
              <button
                onClick={() => window.location.assign(`${appSiteUrl}/signUp`)}
                type="button"
                className="button signUp-type-change"
              >
                Работодателя
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  loginUser: (email, password, vacancyId) => dispatch(loginUser(email, password, vacancyId)),
});


export default connect(null, mapDispatchToProps)(Login);
