import {
  CHECK_USER_AUTHORIZATION_FAIL,
  CHECK_USER_AUTHORIZATION_REQUEST,
  CHECK_USER_AUTHORIZATION_SUCCESS,
  LOAD_INITIAL_DATA_FAIL,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOAD_INITIAL_DATA_SUCCESS,
  EDIT_USER_SETTINGS_SUCCESS,
  SAVE_USER_DATA,
  GET_CITY_SUCCESS,
  GET_COUNTRIES_SUCCESS, WITH_LOADING_ON, WITH_LOADING_OFF,
} from '../constants';
import { createReducer, updateObject } from '../utils/reducerUtils';
import { setAuthorizationToken } from '../utils';


const initialState = {
  authInProgress: false,
  authComplete: false,
  dataLoaded: false,
};

const loadInitialDataSuccess = state => updateObject(state, {
  dataLoaded: true,
});

const loadInitialDataFail = (state, action) => updateObject(state, {
  dataLoaded: false,
  error: action.error,
});

const checkUserAuthorizationRequest = state => updateObject(state, {
  authInProgress: true,
  authComplete: false,
});

const checkUserAuthorizationSuccess = (state, action) => updateObject(state, {
  authInProgress: false,
  authComplete: true,
  user: action.user.id,
  settings: action.user.settings,
});

const checkUserAuthorizationFail = (state) => {
  setAuthorizationToken(null);
  return updateObject(state, {
    authInProgress: false,
    authComplete: false,
    dataLoaded: false,
  });
};

const loginUserRequest = state => updateObject(state, {
  authInProgress: true,
  authComplete: false,
});

const loginUserSuccess = (state, action) => {
  if (!action.data.need_activate) {
    return updateObject(state, {
      authInProgress: false,
      authComplete: true,
      user: action.data.user.id,
      activeTeam: action.data.user.active_team,
      activeTeamShortId: action.data.user.active_team_short_id,
      settings: action.data.user.settings,
    });
  }

  return { ...state, authInProgress: false };
};

const loginUserFail = state => updateObject(state, {
  authInProgress: false,
  authComplete: false,
});

const getCountries = (state, action) => updateObject(state, {
  countries: action.countries,
});

const editUserSettings = (state, action) => updateObject(state, {
  settings: action.user.settings,
});

const saveUserData = (state, action) => {
  const { payload: { email, password } } = action;
  return updateObject(state, { email, password });
};

const saveUserCity = (state, action) => {
  const { data: { suggestions } } = action;
  const [geoData] = suggestions;
  const { data: { city } } = geoData;
  return updateObject(state, { city });
};

const loadingOn = state => updateObject(
  state, { isLoading: true },
);

const loadingOff = state => updateObject(
  state, { isLoading: state.isLoading && false },
);

export default createReducer(initialState, {
  [LOAD_INITIAL_DATA_SUCCESS]: loadInitialDataSuccess,
  [LOAD_INITIAL_DATA_FAIL]: loadInitialDataFail,
  [CHECK_USER_AUTHORIZATION_REQUEST]: checkUserAuthorizationRequest,
  [CHECK_USER_AUTHORIZATION_SUCCESS]: checkUserAuthorizationSuccess,
  [CHECK_USER_AUTHORIZATION_FAIL]: checkUserAuthorizationFail,
  [LOGIN_USER_REQUEST]: loginUserRequest,
  [LOGIN_USER_SUCCESS]: loginUserSuccess,
  [LOGIN_USER_FAIL]: loginUserFail,
  [GET_COUNTRIES_SUCCESS]: getCountries,
  [EDIT_USER_SETTINGS_SUCCESS]: editUserSettings,
  [SAVE_USER_DATA]: saveUserData,
  [GET_CITY_SUCCESS]: saveUserCity,
  [WITH_LOADING_ON]: loadingOn,
  [WITH_LOADING_OFF]: loadingOff,
});
