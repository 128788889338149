import React from 'react';
import { Container } from 'reactstrap';


const Footer = () => (
  <Container className="footer" fluid>
    <div className="footer-text">
      Техническая поддержка:
    </div>
    <div className="footer-email">
      Email:
      <span>
        <a href="mailto:support@jobswidget.me"> support@jobswidget.me</a>
      </span>
    </div>
  </Container>
);

export default Footer;
