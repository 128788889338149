import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import missing from '../assets/img/404.png';


const NotFoundPage = () => (
  <div className="error-section">
    <Helmet>
      <title>Страница не найдена | Jobs Widget</title>
    </Helmet>
    <Link to="/" className="return-link">
      <span className="return-link__arrow" />
      <span className="return-link__text">Вернуться на главную</span>
    </Link>
    <div className="default-img">
      <img src={missing} alt="404" />
    </div>
    <h2 className="error-section__title">Страница не найдена</h2>
    <p className="error-section__text">Неправильно набран адрес, или такой страницы не существует</p>
  </div>
);


export default NotFoundPage;
