import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import appConfig from '../configs/appConfig';

import renderField from './render/renderField';
import ErrorMessage from '../components/ErrorMessage';
import validateLoginForm from './validate/validateLoginForm';


const LoginForm = (props) => {
  const { handleSubmit, submitting } = props;
  const appSiteUrl = appConfig.APP_SITE_URL;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="text"
        component={renderField}
        label="Email"
      />
      <Field
        name="password"
        type="password"
        component={renderField}
        label="Пароль"
      />
      <div className="login-password-restore-link-container">
        <Link to="/restore">Забыли пароль?</Link>
      </div>
      <div className="auth-btns-wrap">
        <button
          type="submit"
          className="button"
          disabled={submitting}
        >
          Войти
        </button>
        <button
          onClick={() => window.location.assign(`${appSiteUrl}/login`)}
          type="button"
          className="button button_color_dark-blue"
        >
          Вход работодателя
        </button>
      </div>
      <ErrorMessage errorName="loginUserError" />
    </form>
  );
};

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'login',
  validate: validateLoginForm,
})(LoginForm);
