import React from 'react';
import PropTypes from 'prop-types';


const FieldError = ({ error, classNames }) => (
  <div className={`render-field-error ${classNames}`}>
    <span className="error-text">{error}</span>
  </div>
);

FieldError.defaultProps = {
  error: '',
  classNames: '',
};

FieldError.propTypes = {
  error: PropTypes.string,
  classNames: PropTypes.string,
};

export default FieldError;
