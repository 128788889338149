import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import TooltipComponent from './TooltipComponent';


const Tooltip = ({ text, className }) => (
  <TooltipComponent
    name={`tooltip-${uuid().slice(0, 8)}`}
    text={text}
    className={className}
  />
);

Tooltip.defaultProps = {
  className: '',
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Tooltip;
