import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import onClickOutside from 'react-onclickoutside';

import { logoutUser } from '../actions/app';


const LARGE_SCREEN_WIDTH = 1600;

class NavigationBar extends React.Component {
  state = {
    isOpen: false,
  };

  openMenu = () => {
    if (window.innerWidth < LARGE_SCREEN_WIDTH) {
      this.setState({ isOpen: true });
    }
  };

  closeMenu = () => {
    if (window.innerWidth < LARGE_SCREEN_WIDTH) {
      this.setState({
        isOpen: false,
      });
    }
  };

  toggleMenu = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  };

  // This function is used to close the navigation bar when clicking outside
  handleClickOutside = () => {
    const { isOpen } = this.state;
    if (isOpen) {
      this.closeMenu();
    }
  };

  logout = () => {
    const { logoutUser } = this.props;
    logoutUser();
  };

  render() {
    const { isOpen } = this.state;
    const {
      name,
      avatar,
      hasUnviewedResponse,
    } = this.props;

    const showTitle = !isOpen && window.innerWidth < LARGE_SCREEN_WIDTH;

    return (
      <nav className={isOpen ? 'navigation active' : 'navigation'}>
        <div className={isOpen ? 'menu-wrap active' : 'menu-wrap'}>
          <div className="menu-logo-helper-hidden" />
          <div className="menu-overflow">
            <LinkContainer to="/" onClick={this.closeMenu}>
              <div className="menu-logo" />
            </LinkContainer>
            <ul className="menu">
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/all-vacancies"
                  title={showTitle ? 'Вакансии' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="bullhorn"
                    />
                  </span>
                  <span className="menu-link-text">Вакансии</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/responses"
                  title={showTitle ? 'Отклики' : undefined}
                  onClick={this.closeMenu}
                >
                  {hasUnviewedResponse && <span className="menu-link-red-dot" />}
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="check-circle"
                    />
                  </span>
                  <span className="menu-link-text">Отклики</span>
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/profile"
                  title={showTitle ? 'Профиль' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="user"
                    />
                  </span>
                  <span className="menu-link-text">Профиль</span>
                </NavLink>
              </li>

              <li className="menu-item">
                <NavLink
                  className="menu-link"
                  to="/resume"
                  title={showTitle ? 'Профиль' : undefined}
                  onClick={this.closeMenu}
                >
                  <span className="icon-wrap">
                    <FontAwesomeIcon
                      className="item-icon"
                      icon="file"
                    />
                  </span>
                  <span className="menu-link-text">Резюме</span>
                </NavLink>
              </li>

              <li className="menu-logout-wrap">
                <span className="icon-wrap" onClick={this.logout}>
                  <FontAwesomeIcon
                    className="item-icon"
                    icon="sign-out-alt"
                  />
                </span>
                <span className="menu-logout" onClick={this.logout}>Выход</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="navigation-panel-top">
          <div className={isOpen ? 'navigation-panel-top-moved active' : 'navigation-panel-top-moved'}>
            <div
              className={isOpen ? 'hamburger hamburger--arrow is-active' : 'hamburger hamburger--arrow'}
              onClick={this.toggleMenu}
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </div>
          <div className="navigation-panel-top-profile">
            <div className="navigation-panel-top-person">
              <LinkContainer to="/profile" onClick={this.closeMenu}>
                <div className="navigation-panel-top-person-avatar">
                  {avatar && <img src={avatar} alt="avatar" />}
                </div>
              </LinkContainer>
              <LinkContainer to="/profile" onClick={this.closeMenu}>
                <span className="navigation-panel-top-person-name">
                  {name}
                </span>
              </LinkContainer>
            </div>
            <div className="navigation-panel-top-logout">
              <span className="navigation-panel-top-logout-text" onClick={this.logout}>Выход</span>
              <span className="icon-wrap" onClick={this.logout}>
                <FontAwesomeIcon
                  className="item-icon"
                  icon="sign-out-alt"
                />
              </span>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

NavigationBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  hasUnviewedResponse: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const {
    entities: { user },
    responses: { has_not_viewed = false },
  } = state;
  const { first_name: name, avatar } = user;
  return ({
    name,
    avatar,
    hasUnviewedResponse: has_not_viewed,
  });
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(onClickOutside(NavigationBar)),
);
