import React from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import KeySkillItem from './KeySkillItem';


const KeySkillItems = ({ onKeySkillDelete, skills }) => (
  <TransitionGroup className="key-skill-container mb-2" component="div">
    {skills && skills.map(({ value }, index) => (
      <CSSTransition
        key={String(index)}
        timeout={800}
        classNames="key-skill"
      >
        <KeySkillItem
          key={String(index) + value}
          skillName={value}
          onDelete={() => onKeySkillDelete(index)}
        />
      </CSSTransition>
    ))}
  </TransitionGroup>
);

KeySkillItems.defaultProps = {
  skills: [],
};

KeySkillItems.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object),
  onKeySkillDelete: PropTypes.func.isRequired,
};

export default KeySkillItems;
