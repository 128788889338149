import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import renderField from '../forms/render/renderField';
import renderSelectField from '../forms/render/renderSelectField';
import { languageOptions } from '../forms/constants/resumeFormOptionsConstants';
import { maxLength, required } from '../forms/validators';


const maxLength32 = maxLength(32);
const renderLanguage = ({
  onDelete, languageLevels, member, languageData: { level },
}) => {
  const [deleteAnimation, setDeleteAnimation] = useState(false);
  const handleDelete = () => {
    setDeleteAnimation(true);
    onDelete();
  };
  return (
    <>
      <div
        className={`
            language-wrapper d-flex
            ${deleteAnimation ? 'language-delete-animation' : 'language-add-animation'}
          `}
      >
        <div className="resume-page-language-fields-wrapper w-100">
          <div className="resume-form-language-name">
            <Field
              name={`${member}language`}
              type="text"
              component={renderField}
              placeholder="Язык"
              label="Язык"
              validate={[required, maxLength32]}
            />
          </div>
          <div className="resume-form-language-level">
            <Field
              name={`${member}level`}
              type="select"
              label="Уровень"
              component={renderSelectField}
              options={languageOptions}
              placeholder="Уровень"
              defaultValue={languageLevels && languageLevels.find((({ value }) => value === level))}
              validate={[required]}
            />
          </div>
        </div>
        <FontAwesomeIcon
          icon="trash-alt"
          onClick={handleDelete}
          className="item-icon"
        />
      </div>
    </>
  );
};

renderLanguage.propTypes = {
  onDelete: PropTypes.func.isRequired,
  languageLevels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default renderLanguage;
