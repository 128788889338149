import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { setAuthorizationToken } from '../utils';
import renderField from '../forms/render/renderField';


const InputSuggestion = (props) => {
  const [suggestionSkills, setSuggestionSkills] = useState([]);
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [delayTimer, setDelayTimer] = useState(null);

  const {
    name,
    value,
    changeFormValue,
    getSuggestions,
    fieldSetName,
    label,
    index,
    classNames,
    placeholder,
    requestWithId,
    errorClassNames,
    idForRequest,
    shouldSaveChoiceId,
    callAfterSuggestionChoiced,
    callRequestWhenFocus,
    ...rest
  } = props;

  const onBlur = () => {
    setTimeout(() => {
      setSuggestionOpen(false);
    }, 150);
  };

  const onChoiceSuggestion = (value) => {
    const fieldName = fieldSetName ? `${fieldSetName}.${name}` : name;
    changeFormValue(fieldName, value);
    if (shouldSaveChoiceId) {
      const { id } = suggestionSkills.find(({ text }) => text === value);
      changeFormValue(`${fieldSetName}.establishments[${index}]university_id`, id);
    }
  };

  const getSuggestionsOptions = currentValue => new Promise((resolve) => {
    clearTimeout(delayTimer);
    const searchDelayTimer = setTimeout(() => {
      const valueForRequest = requestWithId ? idForRequest : currentValue;
      resolve(getSuggestions(valueForRequest)
        .then(({ data }) => {
          const { items = null } = data;
          setSuggestionSkills(items || data);
        })
        .finally(() => setAuthorizationToken(localStorage.getItem('token'))));
    }, 500);
    setDelayTimer(searchDelayTimer);
  });

  useEffect(() => {
    if (idForRequest && requestWithId) {
      getSuggestionsOptions(idForRequest);
    }
  }, [idForRequest]);

  const onInputChange = ({ currentTarget: { value } }) => {
    changeFormValue(name, value);
    if (value.length >= 2 || requestWithId) {
      getSuggestionsOptions(value);
    }
  };

  const onFocus = () => {
    setSuggestionOpen(true);
    if ((value && value.length) > 2 && callRequestWhenFocus) {
      getSuggestionsOptions(value);
    }
  };


  const modItems = suggestionSkills.map(({ text = null, name = null }) => (
    { value: text || name, label: text || name }
  ));

  return (
    <>
      <Field
        name={name}
        value={value}
        component={renderField}
        label={label}
        onChange={(event) => {
          onInputChange(event);
        }}
        {...rest}
        onFocus={onFocus}
        onBlur={onBlur}
        className={`suggestion-input ${classNames} ${suggestionOpen && 'suggestion-input-focus-border'}`}
        errorClassNames={errorClassNames}
        placeholder={placeholder}
        autoComplete="off"
      />
      {suggestionOpen && (
      <div className="suggestion-list-wrapper">
        <ul className="suggestion-list">
          {modItems.length
            ? modItems.map(({ value }, key) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={String(key)} onClick={() => {
                  onChoiceSuggestion(value);
                }}
              >{value}
              </li>
            ))
            : <li>ничего не найдено</li>
              }
        </ul>
      </div>
      )}
    </>
  );
};

InputSuggestion.defaultProps = {
  classNames: '',
  errorClassNames: '',
  idForRequest: null,
  fieldSetName: '',
  value: '',
  index: null,
  requestWithId: false,
  callRequestWhenFocus: false,
  shouldSaveChoiceId: false,
  callAfterSuggestionChoiced: () => {},
};

InputSuggestion.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  fieldSetName: PropTypes.string,
  changeFormValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  errorClassNames: PropTypes.string,
  callAfterSuggestionChoiced: PropTypes.func,
  callRequestWhenFocus: PropTypes.bool,
  shouldSaveChoiceId: PropTypes.bool,
  idForRequest: PropTypes.number,
  index: PropTypes.number,
  requestWithId: PropTypes.bool,
};

export default InputSuggestion;
