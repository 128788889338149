import axios from '../api';
import {
  CREATE_RESUME_REQUEST,
  CREATE_RESUME_SUCCESS,
  EDIT_RESUME_FAIL,
  EDIT_RESUME_REQUEST,
  EDIT_RESUME_SUCCESS,
  GET_RESUME_FAIL,
  GET_RESUME_REQUEST,
  GET_RESUME_SUCCESS,
} from '../constants';
import history from '../routes/history';


export const getResume = cvId => ({
  types: [GET_RESUME_REQUEST, GET_RESUME_SUCCESS, GET_RESUME_FAIL],
  callApi: () => axios.get(`/js/cv/${cvId}/`),
});

export const editResume = (cvId, values) => ({
  types: [EDIT_RESUME_REQUEST, EDIT_RESUME_SUCCESS, EDIT_RESUME_FAIL],
  callApi: () => axios.patch(`/js/cv/${cvId}/`, values),
  afterSuccessCall: () => {
    history.push('/resume');
  },
});

export const createResume = (id, email) => ({
  types: [CREATE_RESUME_REQUEST, CREATE_RESUME_SUCCESS, CREATE_RESUME_SUCCESS],
  callApi: () => axios.post('/js/cv/', { user: id, email }),
});
