import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorMessage from './ErrorMessage';


class ConfirmModal extends React.Component {
  state = {
    modal: false,
  };

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (active !== prevProps.active) {
      this.toggle();
    }
  }

  toggle = () => {
    this.setState(state => ({ modal: !state.modal }));
  };

  actionHandle = () => {
    const { modalAction } = this.props;
    modalAction();
  };

  render() {
    const { modal } = this.state;
    const {
      type,
      icon,
      headerText,
      buttonText,
      warningMessage,
      active,
      disabled,
    } = this.props;

    return (
      <React.Fragment>

        {type === 'icon' && (
          <FontAwesomeIcon
            onClick={this.toggle}
            className="item-icon"
            icon={icon}
          />
        )}
        {type === 'button-icon' && (
          <button
            type="button"
            className="button-add-item span"
            onClick={this.toggle}
          >
            <FontAwesomeIcon
              onClick={this.toggle}
              className="item-icon"
              icon={icon}
            />
            <span className="vacancy-item-description-elem ml-2">{buttonText}</span>
          </button>
        )}
        {type === 'admin' && (
          <FontAwesomeIcon
            onClick={this.toggle}
            className="item color icon_crown"
            color={`${active ? 'orange' : 'grey'}`}
            icon="user-cog"
          />
        )}
        {type === 'button' && (
          <button
            onClick={this.toggle}
            type="button"
            className="button button_color_dark-blue"
          >
            {buttonText}
          </button>
        )}
        {type === 'icon-text' && (
          <button
            type="button"
            className="button-add-item span"
            onClick={this.toggle}
          >
            <FontAwesomeIcon
              className="item-icon"
              icon="trash-alt"
            />
            <span className="vacancy-item-description-elem ml-2">{buttonText}</span>
          </button>
        )}
        {type === 'circle-button-icon' && (
          <button
            onClick={this.toggle}
            type="button"
            className="button-circle"
            icon={icon}
          >
            <FontAwesomeIcon
              className="item-icon"
              icon={icon}
            />
          </button>
        )}
        <Modal isOpen={modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>{headerText}</ModalHeader>
          <ModalFooter>
            <div className="modal-flex">
              <div className="buttons-container">
                <button
                  type="button"
                  className="button"
                  onClick={this.actionHandle}
                  disabled={disabled}
                >
                  { buttonText }
                </button>
                <button
                  type="button"
                  className="button button_color_dark-blue"
                  onClick={this.toggle}
                >
                  Отмена
                </button>
              </div>
              {warningMessage && <div className="form-error">{warningMessage}</div>}
              <ErrorMessage errorName="deleteHiringTemplateError" />
              <ErrorMessage errorName="deleteVacancyError" />
              <ErrorMessage errorName="deleteTeamError" />
              <ErrorMessage errorName="removeUserFromTeamError" />
              <ErrorMessage errorName="toggleAdminRighsError" />
              <ErrorMessage errorName="deleteField" />
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

ConfirmModal.defaultProps = {
  buttonText: 'Удалить',
  warningMessage: '',
  active: false,
  icon: 'trash-alt',
  disabled: false,
};

ConfirmModal.propTypes = {
  type: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  modalAction: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  warningMessage: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ConfirmModal;
