import React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { compose } from 'redux';
import {
  Field,
  FieldArray,
  FormSection,
  isDirty,
  reduxForm,
  stopSubmit,
} from 'redux-form';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import normalizePhone from './normalize';
import renderField from './render/renderField';
import renderFileField from './render/renderFileField';
import validateVacancyForm from './validate/validateVacancyForm';
import * as resumeFields from './constants/resumeFormFieldConstants';

import {
  email,
  matchPattern,
  maxLength,
  phone,
  required,
  resume,
} from './validators';
import renderSelectField from './render/renderSelectField';
import '../styles/layout/ResumeForm.css';
import ErrorMessage from '../components/ErrorMessage';
import renderEducationFieldSet from './render/renderEducationFieldSet';
import renderSkillKeys from './render/renderSkillKeys';
import renderLanguage from './render/renderLanguage';
import {
  currencyOptions,
  experienceOptions,
  scheduleOptions,
  specializationOptions,
} from './constants/vacancyFormConstants';
import { gradeOptions, languageOptions, sexOptions } from './constants/resumeFormOptionsConstants';


const maxLength10000 = maxLength(10000);
const maxLength200 = maxLength(200);
const matchPatternNumber = matchPattern(/^[ 0-9]+$/, 'Можно вводить только числа');


const EditResumeForm = (props) => {
  const validateResumeForm = (fieldType) => {
    switch (fieldType) {
      case 'tel': return phone;
      case 'email': return email;
      case 'textarea': return maxLength10000;
      case 'file': return resume;
      case 'number': return matchPatternNumber;
      default: return maxLength200;
    }
  };

  const getRenderType = (type) => {
    switch (type) {
      case 'file': return renderFileField;
      case 'select': return renderSelectField;
      default: return renderField;
    }
  };

  const getFields = (fields, defaultOptions) => (
    fields.map((field) => {
      const { type } = field;
      return (
        <Field
          key={field.name}
          type={field.type}
          name={field.type === 'file' ? `userFile_${field.name}` : field.name}
          component={getRenderType(type)}
          label={field.type !== 'file' ? field.placeholder
            : `Прикрепить ${field.placeholder && field.placeholder.toLowerCase()}`}
          validate={field.required ? [validateResumeForm(field.type), required]
            : validateResumeForm(field.type)}
          rows="10"
          normalize={field.name === 'phone' ? normalizePhone : undefined}
          accept={field.type === 'file' ? '.jpeg, .jpg, .webp' : undefined}
          info={field.type === 'file' ? 'Допустимы файлы в формате .jpeg, .jpg, .webp, размером не более 10 МБ.'
            : undefined}
          options={field.options && field.options}
          defaultValue={defaultOptions[field.name]}
        />
      );
    })
  );

  const {
    submitFailed,
    handleSubmit,
    submitSucceeded,
    dirty,
    submitting,
    skills,
    languages,
    initialValues,
    change: changeFormValue,
  } = props;

  const defaultOptions = {
    schedule: scheduleOptions.find(option => option.value === initialValues.workInfo.schedule),
    currency: currencyOptions.find(option => option.value === initialValues.workInfo.currency),
    experience: experienceOptions.find(option => option.value === initialValues.workInfo.experience),
    specialization: specializationOptions.find(option => option.value === initialValues.workInfo.specialization),
    gender: sexOptions.find(option => option.value === initialValues.personal.gender),
    grade: gradeOptions.find(option => option.value === initialValues.education.grade),
    languageLevel: initialValues.languages.map(language => languageOptions.find(
      option => option.value === language.level,
    )),
  };

  if (initialValues && languages.length) {
    if (initialValues.languages.length > languages.length) {
      initialValues.languages.pop();
    }
  }

  return (
    <form className="resume-form" onSubmit={handleSubmit}>
      <Prompt message="Вы действительно хотет закрыть форму" when={dirty && !submitSucceeded} />

      <div className="resume-form__field border-bottom pb-3">
        <h3 className="title customization-container__title">Персональная информация</h3>
        <FormSection name="personal">
          {getFields(resumeFields.personalFields, defaultOptions)}
        </FormSection>
      </div>

      <div className="resume-form__field border-bottom pt-4 pb-3">
        <h3 className="title customization-container__title">Желаемая должность и заплата</h3>
        <FormSection name="workInfo">
          {getFields(resumeFields.positionAndSalaryFields, defaultOptions)}
        </FormSection>
      </div>

      <div className="resume-form__field border-bottom pt-4 pb-4">
        <h3 className="title customization-container__title">Образование</h3>
        <FormSection name="education">
          {getFields([resumeFields.educationFields.grade], defaultOptions)}
          <FieldArray
            component={renderEducationFieldSet}
            validateResume={validateResumeForm}
            changeFormValue={changeFormValue}
            name="establishments"
            initialValues={initialValues}
          />
        </FormSection>
      </div>

      <div className="resume-form__field border-bottom pt-4 pb-4">
        <h3 className="title customization-container__title">Ключевые навыки</h3>
        <FieldArray
          component={renderSkillKeys}
          changeformvalue={changeFormValue}
          name="keySkills"
          className="mb-3"
        />
        <FieldArray
          component={renderLanguage}
          name="languages"
          skills={skills}
          languageLevels={defaultOptions.languageLevel}
        />
      </div>

      <div className="resume-form__field pt-3">
        <h3 className="title customization-container__title">Обо мне</h3>
        <Field
          component={renderField}
          className="resume-form-textarea"
          type="textarea"
          name="about"
        />
      </div>

      <div className="buttons-container pt-2">
        <button
          className="button"
          type="submit"
          disabled={submitting}
        >
          Сохранить
        </button>
        <LinkContainer
          to="/resume/"
        >
          <button type="button" className="button button_color_dark-blue">Отмена</button>
        </LinkContainer>
      </div>
      {submitFailed && <div className="form-error">Не все поля заполнены корректно</div>}
      <ErrorMessage errorName="createVacancyError" />
    </form>
  );
};

EditResumeForm.defaultProps = {
  skills: null,
  initialValues: {},
  languages: [],
};

EditResumeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  dirty: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  skills: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.object,
  languages: PropTypes.arrayOf(PropTypes.object),
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const dirty = isDirty('editResume')(state);
  return {
    dirty,
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'editResume',
    validate: validateVacancyForm,
    onChange: (values, dispatch, props) => {
      if (props.submitFailed) {
        dispatch(stopSubmit('editResume'));
      }
    },
  }),
)(EditResumeForm);
