import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import store from '../store';
import missing from '../assets/img/error-img.png';
import history from '../routes/history';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const { app: { user, activeTeam } } = store.getState();
    Sentry.withScope((scope) => {
      scope.setUser({ id: user });
      scope.setExtra('team', activeTeam);
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <div className="container">
          <div className="error-section full-height-wrap section-grey">
            <div className="default-img">
              <img src={missing} alt="error-img" />
            </div>
            <h2 className="error-section__title">Упс, что-то пошло не так :(</h2>
            <div className="error-section__link">
              <Link
                to="/"
                onClick={() => {
                  history.push('/');
                  window.location.reload();
                }}
              >
                Вернуться на главную
              </Link>
            </div>
            <button
              className="button"
              type="button"
              onClick={() => Sentry.showReportDialog()}
            >
              Сообщить об ошибке
            </button>
          </div>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default withRouter(ErrorBoundary);
