import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import history from '../routes/history';


const ConsentToPersonalDataProcessing = () => (
  <div className="container section-padding-sm">
    <Helmet>
      <title>Согласие на обработку персональных данных | Jobs Widget</title>
    </Helmet>
    <Link to="#" className="return-link" onClick={() => history.goBack()}>
      <span className="return-link__arrow" />
      <span className="return-link__text">Вернуться назад</span>
    </Link>
    <h2 className="agreement-title">Согласие на обработку персональных данных</h2>
    <div>
      <p className="agreement-text">
        Настоящим я, далее — «Пользователь», во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О
        персональных данных» (с изменениями и дополнениями) свободно, своей волей и в своем интересе даю свое согласие
        ООО «Технологии Бизнеса» (далее — Администратор) на обработку своих персональных данных указанных при
        использовании сайта (включая любые уровни указанного домена, далее — Сайт).
      </p>
      <p className="agreement-text">
        Под персональными данными я понимаю любую информацию, относящуюся ко мне как к субъекту персональных данных, в
        том числе мои фамилию, имя, отчество, контактные данные (телефон, факс, электронная почта, почтовый адрес), иную
        другую информацию.
      </p>
      <p className="agreement-text">
        Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление, изменение,
        использование, распространение, передачу, в том числе трансграничную, обезличивание, блокирование, уничтожение,
        бессрочное хранение), и любые другие действия (операции) с персональными данными.
      </p>
      <p className="agreement-text">
        Обработка персональных данных Пользователя производится в целях предоставления ему функционала Сайта,
        размещенного
        на нем контента, маркетинговой, рекламной, иной информации, в целях получения пользователем персонализированной
        (таргетированной) рекламы, исследования и анализа данных пользователя, а также в целях продвижения пользователю
        своих товаров и услуг.
      </p>
      <p className="agreement-text">
        В отношении всех сообщенных Администратору пользователем своих персональных данных Администратор вправе
        осуществлять сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование,
        распространение (в том числе передача любым третьим лицам, включая передачу персональных данных третьим лицам на
        хранение или в случае поручения обработки персональных данных третьим лицам), обезличивание, блокирование,
        уничтожение, трансграничная передача, обработка с применением основных способов такой обработки (хранение,
        запись
        на электронных носителях и их хранение, составление перечней, маркировка) и иные действия в соответствии со
        статьей 3 Федерального закона от 27.06.2006 № 152-ФЗ «О персональных данных».
      </p>
      <p className="agreement-text">
        Администратор принимает все необходимые меры для защиты персональных данных пользователя Сайта, а также
        предоставляет к таким данным доступ только тем работникам, подрядчикам и агентам Администратора, которым эта
        информация необходима для обеспечения целей обработки персональных данных Администратором Сайта.
      </p>
      <p className="agreement-text">
        Раскрытие предоставленных Пользователем персональных данных может быть произведено лишь в соответствии с
        законодательством Российской Федерации.
      </p>
      <p className="agreement-text">
        Пользователь понимает и соглашается с тем, что предоставление Администратору какой-либо информации о себе, не
        являющейся контактной и не относящейся к целям, обозначенным Администратором Сайта (не относящейся к
        деятельности
        Администратора, к продвигаемым им товарам и/или услугам, к условиям сотрудничества Администратора и пользователя
        Сайта), а ровно предоставление информации, относящейся к государственной, банковской и/или коммерческой тайне,
        информации о расовой и/или национальной принадлежности, политических взглядах, религиозных или философских
        убеждениях, состоянии здоровья, интимной жизни пользователя Сайта или иного третьего лица запрещено.
      </p>
      <p className="agreement-text">
        Датой выдачи согласия на обработку персональных данных Пользователя является дата регистрации на странице Сайта
        по
        адресу https://app.jobswidget.me/signup или дата заполнения форм с персональными данными и отправки их
        содержимого на сервер на страницах Сайта по адресу https://app.jobswidget.me.
      </p>
    </div>
  </div>
);

export default ConsentToPersonalDataProcessing;
