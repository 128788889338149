import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';


const VacancyPart = ({ title, description }) => (
  <Row className="vacancy-page-part">
    <Col xs="12" className="vacancy-page-description">
      <Row>
        <Col>
          <h2 className="vacancy-item-title">{title}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="vacancy-page-description">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default VacancyPart;

VacancyPart.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
