import {
  validateErrors,
  maxLength,
} from '../validators';


const validate = (values) => {
  const validators = {
    address: [maxLength(200)],
    email: [maxLength(200)],
    about: [maxLength(1500)],
  };


  return { ...validateErrors(values, validators) };
};

export default validate;
