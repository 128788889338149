import { createReducer } from '../utils/reducerUtils';
import { GET_PAYMENT_INFO_SUCCESS } from '../constants';


const getPaymentInfo = (state, action) => action.paymentInfo;

const initialState = {
  itransfer_url: '',
  itransfer_participant_id: '',
  itransfer_order_id: '',
  itransfer_order_currency_ident: '',
  itransfer_language_ident: '',
  itransfer_participant_ident: '',
  itransfer_body_type: '',
  itransfer_url_returnsuccess: '',
  itransfer_testmode: '',
  itransfer_person_name: '',
  itransfer_order_description: '',
  itransfer_item1_quantity: '',
  itransfer_item1_measure: '',
  itransfer_item1_vatrate: '',
  itransfer_item1_vat: '',
  itransfer_participant_sign_month: '',
  itransfer_participant_sign_year: '',
};

export default createReducer(initialState, {
  [GET_PAYMENT_INFO_SUCCESS]: getPaymentInfo,
});
