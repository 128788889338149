import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Breadcrumbs from '../components/Breadcrumbs';
import EditResumeForm from '../forms/EditResumeForm';
import { editResume, getResume } from '../actions/resume';


const EditResume = (props) => {
  const { user } = props;

  const handleSubmit = (values) => {
    const { editResume, resumeId, user: { id } } = props;
    const {
      personal,
      education,
      workInfo: work_info,
      keySkills: key_skills,
      languages,
      about,
    } = values;

    const updatedValues = {
      user: id,
      personal,
      ...education,
      work_info,
      key_skills,
      languages,
      about,
    };

    const { personal: { birth_date } } = updatedValues;
    updatedValues.personal.birth_date = birth_date && birth_date
      .split('-')
      .reverse()
      .join('.');

    editResume(resumeId, updatedValues);
  };

  const breadcrumbs = [
    { title: 'Резюме', link: '/resume' },
    { title: 'Редактирование резюме', link: '' },
  ];

  const { match: { params: { editType } }, resumeData } = props;

  const updateResumeData = () => {
    const {
      first_name, last_name, phone, email,
    } = user;

    const { personal } = resumeData;
    const { birth_date } = personal;
    const modBirthDate = birth_date && birth_date.split('.').reverse().join('-');

    return {
      ...resumeData,
      personal: {
        first_name,
        last_name,
        phone,
        email,
        ...personal,
        birth_date: modBirthDate,
      },
    };
  };

  return (
      <>
        <Helmet>
          <title>Редактирование резюме | Jobs Widget</title>
        </Helmet>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <EditResumeForm
          onSubmit={handleSubmit}
          initialValues={updateResumeData()}
          editType={editType}
        />
      </>
  );
};

EditResume.propTypes = {
  match: PropTypes.object.isRequired,
  resumeId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const {
    resume: resumeData,
    entities: { user },
  } = state;
  const { cv: resumeId } = user;
  return {
    user,
    resumeId,
    resumeData,
  };
};

const mapDispatchToProps = dispatch => ({
  editResume: (cvId, values) => dispatch(editResume(cvId, values)),
  getResume: cvId => dispatch(getResume(cvId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditResume));
