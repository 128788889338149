import configureStore from './configureStore';


const store = configureStore();

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

export default store;
