import { useRef, useState } from 'react';


const SIZE_LIMIT_IN_MEGABYTES = 10;
const BYTES_IN_MEGABYTE = 1048576;
const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];

const useImageDownload = () => {
  const [errorText, setErrorText] = useState('');
  const [avatarImage, setAvatarImage] = useState('');
  const [image, setImage] = useState('');
  const fileRef = useRef(null);

  const getBase64FromBlob = blob => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

  const resetImage = () => {
    setImage('');
    setAvatarImage('');
    fileRef.current.value = null;
  };

  const setAvatar = (file) => {
    setAvatarImage(file);
    getBase64FromBlob(file)
      .then((base64) => {
        setImage(base64);
      });
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        const errorText = `${file.name} имеет недопустимый формат. Используйте ${ALLOWED_EXTENSIONS.join(', ')}.`;
        setErrorText(errorText);
      } else if (file.size > SIZE_LIMIT_IN_MEGABYTES * BYTES_IN_MEGABYTE) {
        const errorText = `${file.name} превышает максимальный разрешенный размер в ${SIZE_LIMIT_IN_MEGABYTES} МБ.`;
        setErrorText(errorText);
      } else {
        setAvatar(file);
        setErrorText('');
      }
    }
  };

  const onFileClick = () => {
    const fileInput = fileRef.current;
    fileInput.click();
  };
  return [fileRef, onFileChange, onFileClick, avatarImage, image, resetImage, errorText];
};

export default useImageDownload;
