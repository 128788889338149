import { combineReducers } from 'redux';

import userReducer from './users';
import vacanciesReducer from './vacancies';


const entitiesReducer = combineReducers({
  user: userReducer,
  allVacancies: vacanciesReducer,
});

export default entitiesReducer;
