import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import appConfig from '../configs/appConfig';

import { checkUserHaveAccount, signUpUser } from '../actions/app';
import SignUpForm from '../forms/SignUpForm';
import ErrorMessage from '../components/ErrorMessage';
import SignUpFormFirstStep from '../forms/SignUpFormFirstStep';


class SignUp extends Component {
  constructor(props) {
    super(props);
    const { location: { search } } = this.props;
    const locationSearch = new URLSearchParams(search);
    this.state = {
      step: +locationSearch.get('step') || 1,
    };
  }

  componentDidUpdate() {
    const { location: { search } } = this.props;
    const { step } = this.state;
    const locationSearch = new URLSearchParams(search);
    const newStep = +locationSearch.get('step') || 1;
    if (newStep !== step) {
      this.onStepUpdate(newStep);
    }
  }

  onStepUpdate(newStep) {
    this.setState({ step: newStep });
  }

  handleSubmit = (values) => {
    const { step } = this.state;
    const {
      firstName: first_name,
      lastName: last_name,
      email,
      password,
    } = values;
    const { signUpUser, checkUserHaveAccount } = this.props;
    if (step === 1) {
      checkUserHaveAccount({ email });
    } else {
      signUpUser(first_name, last_name, email, password);
    }
  };

  render() {
    const { location } = this.props;
    const { step } = this.state;
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const team = searchParams.get('team');
    const initialValues = {
      email,
    };
    const appSiteUrl = appConfig.APP_SITE_URL;
    return (
      <div className="full-height-wrap section-grey">
        <Helmet>
          <title>Регистрация | Jobs Widget</title>
        </Helmet>
        <h2 className="auth-title">Регистрация соискателя</h2>
        <div className="auth-content">
          {team && (
            <div>
              <h2 className="auth-title ">
                Вас пригласили в команду
                {' '}
                {team}
              </h2>
              <div className="auth-text">Зарегистрируйтесь в сервисе, чтобы принять приглашение</div>
            </div>
          )}
          {step === 1 && (
            <>
              <SignUpFormFirstStep
                onSubmit={this.handleSubmit}
                initialValues={email}
              />
              <ErrorMessage errorName="userHaveAccountFail" />
            </>
          )}
          {step === 2 && (
            <SignUpForm
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
            />
          )}
          <hr />
          <div>
            <h3 className="text-center">Вход</h3>
            <div className="signUp-type-change-wrapper mt-4">
              <Link to="/login" className="button signUp-type-change">Соискателя</Link>
              <button
                onClick={() => window.location.assign(`${appSiteUrl}/login`)}
                type="button"
                className="button signUp-type-change"
              >
                Работодателя
              </button>
            </div>
          </div>
          <div className="auth-license-text">
            Нажимая кнопку зарегистрироваться вы принимаете
            {' '}
            <Link to="/contest-to-personal-data-processing">соглашение на обработку персональных данных</Link>
          </div>
          <br />
          <ErrorMessage errorName="registerUserError" />
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  signUpUser: PropTypes.func.isRequired,
  checkUserHaveAccount: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  signUpUser: (first_name, last_name, email, password) => dispatch(signUpUser(first_name, last_name, email, password)),
  checkUserHaveAccount: values => dispatch(checkUserHaveAccount(values)),
});

export default withRouter(
  connect(null, mapDispatchToProps)(SignUp),
);
