import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetApiStatus } from '../actions/app';


class StatusMessage extends React.Component {
  componentWillUnmount() {
    const { resetApiStatus, status } = this.props;
    resetApiStatus(status);
  }

  render() {
    const {
      successMessage,
      errorMessage,
      apiStatus,
    } = this.props;
    return ((apiStatus === 'success' && successMessage) || (apiStatus === 'fail' && errorMessage))
      ? (
        <div className={`status-message-${apiStatus}`}>
          {apiStatus === 'success' && successMessage}
          {apiStatus === 'fail' && errorMessage}
        </div>
      )
      : null;
  }
}

StatusMessage.defaultProps = {
  successMessage: '',
  errorMessage: '',
  apiStatus: '',
};

StatusMessage.propTypes = {
  status: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  apiStatus: PropTypes.string,
  resetApiStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  apiStatus: state.api[ownProps.status],
});

const mapDispatchToProps = dispatch => ({
  resetApiStatus: name => dispatch(resetApiStatus(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusMessage);
