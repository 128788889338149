import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import StyledSelect from '../../components/StyledSelect';
import FieldError from '../../components/FieldError';
import Tooltip from '../../components/Tooltip';


const renderSelectField = (props) => {
  const {
    input: { value, onChange, onBlur },
    label,
    options,
    defaultValue,
    meta: { touched, error },
    placeholder,
    noOptionsMessage,
    tooltipText,
  } = props;
  return (
    <FormGroup>
      <Label>{label}</Label>
      {tooltipText && <Tooltip text={tooltipText} />}
      <StyledSelect
        onChange={data => onChange(data.value)}
        onBlur={() => onBlur(value)}
        options={options}
        defaultValue={defaultValue}
        isSearchable={false}
        blurInputOnSelect={false}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
      />
      {touched && error && <FieldError error={error} />}
    </FormGroup>
  );
};

renderSelectField.defaultProps = {
  noOptionsMessage: 'Не найдено',
  placeholder: 'Выберите...',
  defaultValue: false,
  tooltipText: '',
};

renderSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  tooltipText: PropTypes.string,
};


export default renderSelectField;
