export const gradeOptions = [
  {
    label: 'Среднее',
    value: 'secondary',
  },

  {
    label: 'Среднее специальное',
    value: 'secondarySpecial',
  },

  {
    label: 'Неоконченное высшее',
    value: 'incompleteHigher',
  },

  {
    label: 'Высшее',
    value: 'higher',
  },

  {
    label: 'Бакалавар',
    value: 'bachelor',
  },

  {
    label: 'Магистр',
    value: 'master',
  },

  {
    label: 'Кандидат наук',
    value: 'candidateOfSciences',
  },

  {
    label: 'Доктор наук',
    value: 'doctorOfSciences',
  },
];

export const sexOptions = [
  { label: 'Мужской', value: 'male' },
  { label: 'Женский', value: 'female' },
];

export const languageOptions = [
  {
    label: 'A1 - Начальный',
    value: 'A1',
  },
  {
    label: 'A2 - Элементарный',
    value: 'A2',
  },
  {
    label: 'B1 - Средний',
    value: 'B1',
  },
  {
    label: 'B2 - Средне-продвинутый',
    value: 'B2',
  },
  {
    label: 'C1 - Продвинутый',
    value: 'C1',
  },
  {
    label: 'C2 - В совершенстве',
    value: 'C2',
  },
  {
    label: 'Носитель языка',
    value: 'native',
  },
];
