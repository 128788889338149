import appConfig from '../configs/appConfig';


export class WSCommands {
  static SHOW_MESSAGE = 'showMessage';

  static SET_VACANCIES = 'setVacancies';

  static IMPORT_HH_VACANCIES = 'importHhVacancies'
}


class WebsocketService {
  static instance = null;

  debug = false;

  callbacks = {};

  static getInstance() {
    if (!WebsocketService.instance) {
      WebsocketService.instance = new WebsocketService();
    }
    return WebsocketService.instance;
  }

  constructor() {
    this.socketRef = null;
    this.debug = process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'dev';
  }

  logging(...message) {
    if (this.debug) {
      console.log(...message);
    }
  }

  connect() {
    const authToken = localStorage.getItem('token');
    document.cookie = `X-Authorization=${authToken}; path=/`;
    const path = `${appConfig.WEBSOCKET_URL}/core/`;
    // eslint-disable-next-line no-undef
    this.socketRef = new WebSocket(path);
    this.socketRef.onopen = () => {
      this.logging('opened');
    };
    this.socketRef.onmessage = (e) => {
      this.socketNewData(e.data);
    };
    this.socketRef.onerror = (e) => {
      this.logging('error', e);
    };
    this.socketRef.onclose = () => {
      this.logging('closed');
      this.connect();
    };
  }

  state() {
    return this.socketRef.readyState;
  }

  socketNewData(rawData) {
    const parsedData = JSON.parse(rawData);
    this.logging('Parsed data', parsedData);
    const { command, data } = parsedData;

    if (this.callbacks[command]) {
      this.callbacks[command](data);
    }
  }

  addCallbacks = (callbacks) => {
    this.waitForSocketConnection(() => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(callbacks)) {
        this.callbacks[key] = callbacks[key];
      }
      this.setDefaultCallbacks();
      this.logging('added callbacks');
    });
  };

  setDefaultCallbacks() {
    this.callbacks[WSCommands.SET_VACANCIES] = this.setVacancies;
  }

  removeCallbacks(callbackNames) {
    callbackNames.forEach((name) => {
      delete this.callbacks[name];
    });
  }

  waitForSocketConnection = (callback) => {
    const socket = this.socketRef;
    const recursion = this.waitForSocketConnection;
    setTimeout(() => {
      if (socket.readyState === 1) {
        if (callback) {
          callback();
        }
      } else {
        this.logging('waiting for connection...');
        recursion(callback);
      }
    }, 100);
  };

  sendMessage(data) {
    this.waitForSocketConnection(() => {
      this.socketRef.send(JSON.stringify(data));
    });
  }
}

const WebSocketInstance = WebsocketService.getInstance();

export default WebSocketInstance;
