import Axios from 'axios';
import store from '../store';
import axios, { hhAxios } from '../api';
import {
  CHECK_USER_AUTHORIZATION_FAIL,
  CHECK_USER_AUTHORIZATION_REQUEST,
  CHECK_USER_AUTHORIZATION_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  LOAD_INITIAL_DATA_FAIL,
  LOGIN_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  LOGOUT_USER_REQUEST,
  LOGOUT_USER_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  LOAD_INITIAL_DATA_SUCCESS,
  RESET_API_STATUS,
  RESET_ERROR,
  GET_COUNTRIES_REQUEST,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAIL,
  CHANGE_PASSWORD_AFTER_RESTORE_REQUEST,
  CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS,
  CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
  EDIT_USER_SETTINGS_REQUEST,
  EDIT_USER_SETTINGS_SUCCESS,
  EDIT_USER_SETTINGS_FAIL,
  SAVE_USER_DATA,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAIL,
  CHECK_USER_HAVE_ACCOUNT_FAIL,
  CHECK_USER_HAVE_ACCOUNT_SUCCESS,
  CHECK_USER_HAVE_ACCOUNT_REQUEST,
  GET_CITY_REQUEST,
  GET_CITY_SUCCESS,
  GET_CITY_FAIL,
} from '../constants';
import { setAuthorizationToken } from '../utils';
import history from '../routes/history';
import { loadResponses } from './responses';
import { createResume, getResume } from './resume';


export const loadInitialData = () => async (dispatch) => {
  const { entities: { user: { cv = null, id, email } } } = store.getState();
  try {
    const { app: { authComplete } } = store.getState();
    const resumeRequest = cv ? getResume(cv) : createResume(id, email);
    if (authComplete) {
      await Promise.all([
        dispatch(loadResponses()),
        dispatch(resumeRequest),
      ]);
    }
    dispatch({ type: LOAD_INITIAL_DATA_SUCCESS });
  } catch (error) {
    dispatch({ type: LOAD_INITIAL_DATA_FAIL, error });
  }
};

export const checkUserAuthorization = (token) => {
  setAuthorizationToken(token);
  return {
    name: 'user',
    types: [CHECK_USER_AUTHORIZATION_REQUEST, CHECK_USER_AUTHORIZATION_SUCCESS, CHECK_USER_AUTHORIZATION_FAIL],
    callApi: () => axios.get('js/me/'),
  };
};

export const saveUserData = (email, password) => ({
  type: SAVE_USER_DATA,
  payload: { email, password },
});

export const resetUserData = () => ({
  type: SAVE_USER_DATA,
  payload: { email: '', password: '' },
});

export const loginUser = (email, password, vacancyToRedirect) => ({
  types: [LOGIN_USER_REQUEST, LOGIN_USER_SUCCESS, LOGIN_USER_FAIL],
  callApi: () => axios.post('/auth/login/', { email, password }),
  afterSuccessCall: (response, dispatch) => {
    const { need_activate: needActivate, token } = response;
    if (needActivate) {
      dispatch(saveUserData(email, password));
      history.push('/login/act/');
    } else {
      localStorage.setItem('token', token);
      setAuthorizationToken(token);
      dispatch(loadInitialData());
      dispatch(resetUserData());
      if (vacancyToRedirect) {
        history.push(`/all-vacancies/${vacancyToRedirect}`);
      } else {
        history.push('/');
      }
    }
  },
});

export const logoutUser = () => ({
  types: [LOGOUT_USER_REQUEST, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAIL],
  callApi: () => {
    const response = axios.post('/auth/logout/');
    localStorage.removeItem('token');
    setAuthorizationToken(false);
    return response;
  },
});

export const signUpUser = (first_name, last_name, email, password) => ({
  name: 'user',
  types: [REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAIL],
  callApi: () => axios.post('/auth/users/', {
    first_name,
    last_name,
    email,
    password,
  }),
  afterSuccessCall: () => history.push(`/signup/success?email=${email}`),
});

export const restorePassword = email => ({
  types: [RESTORE_PASSWORD_REQUEST, RESTORE_PASSWORD_SUCCESS, RESTORE_PASSWORD_FAIL],
  callApi: () => axios.post('/auth/password/reset/', { email }),
  afterSuccessCall: () => history.push(`/restore/success?email=${email}`),
});

export const changePasswordAfterRestore = (new_password, re_new_password, uid, token) => ({
  types: [
    CHANGE_PASSWORD_AFTER_RESTORE_REQUEST,
    CHANGE_PASSWORD_AFTER_RESTORE_SUCCESS,
    CHANGE_PASSWORD_AFTER_RESTORE_FAIL,
  ],
  callApi: () => axios.post('/auth/password/reset/confirm/', {
    new_password,
    re_new_password,
    uid,
    token,
  }),
  afterSuccessCall: () => history.push('/login'),
});

export const editProfile = (id, user) => ({
  name: 'user',
  types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAIL],
  callApi: () => axios.patch(`/auth/users/${id}/`, user),
});

export const editUserSettings = (id, settings) => ({
  name: 'user',
  types: [EDIT_USER_SETTINGS_REQUEST, EDIT_USER_SETTINGS_SUCCESS, EDIT_USER_SETTINGS_FAIL],
  callApi: () => axios.patch(`/auth/users/${id}/`, { settings }),
});


export const resetApiStatus = name => ({
  type: RESET_API_STATUS,
  name,
});

export const resetError = name => ({
  type: RESET_ERROR,
  name,
});

export const getCountries = () => ({
  name: 'countries',
  types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL],
  callApi: () => axios.get('/countries/'),
});

export const getCities = (country, city) => axios.get('/cities/', { params: { country, city } });

export const getAddresses = (country, city, address) => axios.get('/addresses/', {
  params: { country, city, address },
});

export const checkUserHaveAccount = values => ({
  types: [CHECK_USER_HAVE_ACCOUNT_REQUEST, CHECK_USER_HAVE_ACCOUNT_SUCCESS, CHECK_USER_HAVE_ACCOUNT_FAIL],
  callApi: () => axios.get('/auth/users/exists/', { params: { email: values.email } }),
  afterSuccessCall: ({ can_activate }) => {
    if (can_activate) {
      history.push(`/signup/act?email=${values.email}`);
    } else {
      history.push(`/signup?step=2&email=${values.email}`);
    }
  },
});

export const employeeStatusActivation = values => ({
  types: [ACTIVATE_ACCOUNT_REQUEST, ACTIVATE_ACCOUNT_SUCCESS, ACTIVATE_ACCOUNT_FAIL],
  callApi: () => axios.post('/auth/users/activate/', values),
  afterSuccessCall: () => {
    history.push('/login/act/success');
  },
});

export const getCityOfUser = (url, option) => {
  const data = JSON.parse(option.body);
  const config = {
    headers: {
      Authorization: option.headers.Authorization,
    },
  };
  return {
    types: [GET_CITY_REQUEST, GET_CITY_SUCCESS, GET_CITY_FAIL],
    callApi: () => Axios.post(url, data, config),
  };
};

export const getKeySkillSuggestions = (text) => {
  delete hhAxios.defaults.headers.authorization;
  return hhAxios.get(`suggests/skill_set/?text=${text}`);
};

export const getEducationalInstitutions = (text) => {
  delete hhAxios.defaults.headers.authorization;
  return hhAxios.get(`suggests/educational_institutions/?text=${text}`);
};

export const getFacuclties = (id) => {
  delete hhAxios.defaults.headers.authorization;
  return hhAxios.get(`/educational_institutions/${id}/faculties`);
};

export const getSpecialization = (text) => {
  delete hhAxios.defaults.headers.authorization;
  return hhAxios.get(`suggests/fields_of_study/?text=${text}`);
};
