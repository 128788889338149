import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import { checkUserAuthorization, loadInitialData } from '../actions/app';


class MainRouter extends Component {
  async componentDidMount() {
    const { checkUserAuthorization, loadInitialData, location } = this.props;

    if (location.pathname === '/login') {
      const searchParams = new URLSearchParams(location.search);
      localStorage.setItem('token', searchParams.get('token'));
    }
    const token = localStorage.getItem('token');

    if (token) {
      await checkUserAuthorization(token);
      await loadInitialData();
    }
  }

  render() {
    const {
      authInProgress,
      authComplete,
      dataLoaded,
    } = this.props;
    const token = localStorage.getItem('token');

    if ((token && !authComplete) || authInProgress) {
      return <div />;
    }

    if (authComplete) {
      return <PrivateRoutes dataLoaded={dataLoaded} />;
    }
    return <PublicRoutes />;
  }
}

MainRouter.propTypes = {
  authInProgress: PropTypes.bool.isRequired,
  authComplete: PropTypes.bool.isRequired,
  dataLoaded: PropTypes.bool.isRequired,
  checkUserAuthorization: PropTypes.func.isRequired,
  loadInitialData: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    app: {
      authInProgress,
      authComplete,
      dataLoaded,
      activeTeam,
    },
  } = state;
  return ({
    authInProgress,
    authComplete,
    dataLoaded,
    activeTeam,
  });
};

const mapDispatchToProps = dispatch => ({
  checkUserAuthorization: token => dispatch(checkUserAuthorization(token)),
  loadInitialData: () => dispatch(loadInitialData()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainRouter),
);
