import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import ErrorMessage from '../components/ErrorMessage';
import ChangePasswordAfterRestoreForm from '../forms/ChangePasswordAfterRestoreForm';
import { changePasswordAfterRestore } from '../actions/app';


const ChangePasswordAfterRestorePage = (props) => {
  const { match, changePasswordAfterRestore } = props;
  const { params: { uid, token } } = match;
  return (
    <div className="full-height-wrap section-grey">
      <Helmet>
        <title>Восстановление пароля | Jobs Widget</title>
      </Helmet>
      <h2 className="auth-title">Восстановление пароля</h2>
      <div className="auth-content">
        <ChangePasswordAfterRestoreForm
          onSubmit={({ newPassword, reNewPassword }) => changePasswordAfterRestore(
            newPassword,
            reNewPassword,
            uid,
            token,
          )}
        />
        <ErrorMessage errorName="changePasswordAfterRestoreError" />
      </div>
    </div>
  );
};

ChangePasswordAfterRestorePage.propTypes = {
  changePasswordAfterRestore: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  changePasswordAfterRestore: (newPassword, reNewPassword, uid, token) => dispatch(
    changePasswordAfterRestore(newPassword, reNewPassword, uid, token),
  ),
});

export default connect(null, mapDispatchToProps)(ChangePasswordAfterRestorePage);
