import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Field } from 'redux-form';
import { maxLength, required } from '../validators';
import renderField from './renderField';


const maxLength10000 = maxLength(10000);

const renderQuestionsField = (props) => {
  const {
    questions,
    vacancy,
    location: { pathname },
  } = props;

  let responseQuestions;
  if (pathname.includes('all-vacancies')) {
    responseQuestions = [...questions];
  } else {
    responseQuestions = questions.filter(question => vacancy.questions.some(
      vacancyQuestion => question.id === vacancyQuestion,
    ));
  }

  return (
    responseQuestions && responseQuestions.length > 0 && (
      <div className="vacancy-questions vacancy-form__questions">
        <div className="title vacancy-questions__title">Вопросы</div>
        {responseQuestions.map((questionObject, index) => (
          <div className="vacancy-questions__item" key={`${questionObject.id}`}>
            <Field
              type="textarea"
              validate={[maxLength10000, required]}
              name={`questions[${index}]._${questionObject.id}`}
              component={renderField}
              label={questionObject.message.replace(/<[^>]+>/g, '')}
              placeholder="Введите ответ"
              rows="10"
            />
          </div>
        ))}
      </div>
    )
  );
};

renderQuestionsField.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  vacancy: PropTypes.PropTypes.object.isRequired,
};

export default withRouter(renderQuestionsField);
