import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import renderField from './render/renderField';
import validateChangePasswordForm from './validate/validateChangePasswordForm';
import ErrorMessage from '../components/ErrorMessage';
import StatusMessage from '../containers/StatusMessage';


const ChangePasswordForm = (props) => {
  const {
    handleSubmit,
    pristine,
    submitting,
    changePasswordStatus,
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="password"
        type="password"
        component={renderField}
        label="Старый пароль"
      />
      <Field
        name="newPassword"
        type="password"
        component={renderField}
        label="Новый пароль"
      />
      <Field
        name="reNewPassword"
        type="password"
        component={renderField}
        label="Подтвердите пароль"
      />
      <button
        type="submit"
        className="button"
        disabled={pristine || submitting}
      >
        Сменить
      </button>
      {changePasswordStatus === 'success' && (
        <StatusMessage
          status="changePasswordStatus"
          successMessage="Пароль успешно изменен"
        />
      )}
      {changePasswordStatus === 'fail' && <ErrorMessage errorName="changePasswordFail" />}
    </form>
  );
};

ChangePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  changePasswordStatus: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'changePassword',
  validate: validateChangePasswordForm,
})(ChangePasswordForm);
