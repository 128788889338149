import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';

import renderField from './render/renderField';
import { validateSecondStep } from './validate/validateSignUpForm';


const ActivateAccountRoleForm = (props) => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit} className="text-left mt-4">
      <Field
        name="email"
        type="email"
        component={renderField}
        label="Email"
        disabled
      />
      <Field
        name="password"
        type="password"
        component={renderField}
        label="Пароль"
      />
      <div className="auth-btns-wrap">
        <button
          type="submit"
          className="button"
          disabled={submitting}
        >
          Продолжить
        </button>
      </div>
    </form>
  );
};

ActivateAccountRoleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'accountActivateForm',
  validate: validateSecondStep,
})(ActivateAccountRoleForm);
