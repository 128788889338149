import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import callApiMiddleware from '../middlewares/callApiMiddleware';
import rootReducer from '../reducers';


export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware, callApiMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composeEnhancers = composeWithDevTools(...enhancers);

  return createStore(rootReducer, preloadedState, composeEnhancers);
}
