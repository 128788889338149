import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { toast, ToastContainer, Slide } from 'react-toastify';

import NavigationBar from '../containers/NavigationBar';
import ResponsesPage from '../containers/ResponsesPage';
import ProfilePage from '../containers/ProfilePage';
import Footer from '../components/Footer';
import LicenseAgreement from '../components/LicenseAgreement';
import NotFoundPage from '../components/NotFoundPage';
import preloader from '../assets/img/preloader.gif';
import WebSocketInstance, { WSCommands } from '../utils/websocket';
import AllVacanciesPage from '../containers/AllVacanciesPage';
import VacancyPage from '../containers/VacancyPage';
import ResponseReview from '../containers/ResponseReview';
import ResumePage from '../containers/ResumePage';
import EditResume from '../containers/EditResume';


export default class PrivateRoutes extends Component {
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      if (localStorage.getItem('token')) {
        WebSocketInstance.connect();
        WebSocketInstance.addCallbacks({ [WSCommands.SHOW_MESSAGE]: this.onNewMessage });
      }
    }
  }

  componentWillUnmount() {
    if (process.env.NODE_ENV === 'production') {
      WebSocketInstance.removeCallbacks([WSCommands.SHOW_MESSAGE]);
    }
  }

  onNewMessage = (data) => {
    const { message, status } = data;
    toast[status](message);
  };

  render() {
    const { dataLoaded } = this.props;
    return (
      <React.Fragment>
        <ToastContainer
          transition={Slide}
          autoClose={15000}
          newestOnTop
          pauseOnFocusLoss
          hideProgressBar
        />
        <NavigationBar />
        <Container className="private-page-container">
          {dataLoaded
            ? (
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/all-vacancies" />}
                />
                <Route
                  path="/all-vacancies"
                  exact
                  component={AllVacanciesPage}
                />
                <Route
                  path="/all-vacancies/:id"
                  exact
                  component={VacancyPage}
                />
                <Route
                  path="/responses"
                  exact
                  component={ResponsesPage}
                />
                <Route
                  path="/responses/:responseId"
                  exact
                  component={ResponseReview}
                />
                <Route
                  path="/profile"
                  exact
                  component={ProfilePage}
                />
                <Route
                  path="/license-agreement"
                  exact
                  component={LicenseAgreement}
                />
                <Route
                  path="/login"
                  exact
                  render={() => <Redirect to="/" />}
                />
                <Route
                  path="/signup"
                  exact
                  render={() => <Redirect to="/" />}
                />
                <Route
                  path="/resume"
                  exact
                  component={ResumePage}
                />
                <Route
                  path="/resume/edit-resume/"
                  exact
                  component={EditResume}
                />
                <Route
                  path="*"
                  component={NotFoundPage}
                />
              </Switch>
            )
            : (
              <div className="loader">
                <img className="loader__gif" src={preloader} alt="Загрузка..." />
              </div>
            )}
        </Container>
        <Footer />
      </React.Fragment>
    );
  }
}

PrivateRoutes.propTypes = {
  dataLoaded: PropTypes.bool.isRequired,
};
