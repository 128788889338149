import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import renderField from './render/renderField';
import validateChangePasswordAfterRestoreForm from './validate/validateChangePasswordAfterRestoreForm';


const ChangePasswordAfterRestoreForm = (props) => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="newPassword"
        type="password"
        component={renderField}
        label="Новый пароль"
      />
      <Field
        name="reNewPassword"
        type="password"
        component={renderField}
        label="Подтвердите пароль"
      />
      <div className="auth-btns-wrap">
        <button
          type="submit"
          className="button button_color_dark-blue"
          disabled={submitting}
        >
          Сменить
        </button>
        <Link to="/login">Отмена</Link>
      </div>
    </form>
  );
};

ChangePasswordAfterRestoreForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'changePasswordAfterRestore',
  validate: validateChangePasswordAfterRestoreForm,
})(ChangePasswordAfterRestoreForm);
