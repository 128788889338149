import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { connect } from 'react-redux';
import { compose } from 'redux';

import StyledSelect from '../components/StyledSelect';
import { editUserSettings } from '../actions/app';
import { scrollToElement } from '../utils';
import history from '../routes/history';


const withPagination = (WrappedComponent, name) => class extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    ),
    location: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    editUserSettings: PropTypes.func.isRequired,
    user: PropTypes.number,
  };

  static defaultProps = {
    items: [],
    user: null,
  };

  constructor(props) {
    super(props);
    const { location, settings, items } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const currentPage = parseInt(searchParams.get('page'), 10) - 1;
    const itemsPerPage = parseInt(searchParams.get('size'), 10);
    const defaultItemsPerPage = itemsPerPage >= 0 ? itemsPerPage : (settings.page_sizes[name] || 10);
    const maxPage = items.length && Math.floor((items.length - 1) / defaultItemsPerPage);
    this.state = {
      currentPage: (currentPage >= 0 && currentPage <= maxPage) ? currentPage : 0,
      itemsPerPage: defaultItemsPerPage,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location: { search } } = nextProps;
    const { currentPage } = prevState;
    const searchParams = new URLSearchParams(search);

    if (!searchParams.get('page')) {
      return { currentPage: 0 };
    }
    if (searchParams.get('page') !== currentPage + 1) {
      return { currentPage: searchParams.get('page') - 1 };
    }

    return null;
  }

  componentDidMount() {
    const { items, location, settings } = this.props;
    const itemsPerPage = settings.page_sizes[name];
    const hash = location.hash && parseInt(location.hash.slice(1), 10);
    if (hash) {
      const index = (items && items.findIndex(item => item.id === hash));
      const currentPage = index !== -1 ? Math.floor(index / itemsPerPage) : 0;
      this.setState({ currentPage, itemsPerPage });
      setTimeout(() => scrollToElement(`scroll-position-${hash}`));
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.resetCurrentPage();
    }
  }

  resetCurrentPage = () => {
    const { location } = this.props;
    this.setState({ currentPage: 0 });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    history.replace({ pathname: location.pathname, search: `?${searchParams}` });
    window.scrollTo(0, 0);
  };

  handlePageSizeSelector = (option) => {
    const {
      editUserSettings,
      user,
      settings,
      location,
    } = this.props;

    if (name) {
      editUserSettings(
        user,
        {
          ...settings,
          page_sizes: {
            ...settings.page_sizes,
            [name]: option.value,
          },
        },
      );
    }
    this.setState({
      currentPage: 0,
      itemsPerPage: option.value,
    });
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '1');
    searchParams.set('size', option.value);
    history.replace({ pathname: location.pathname, search: `?${searchParams}` });
    window.scrollTo(0, 0);
  };

  handlePageChange = (data) => {
    const { itemsPerPage } = this.state;
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', data.selected + 1);
    searchParams.set('size', itemsPerPage);
    history.replace({ pathname: location.pathname, search: `?${searchParams}` });
    this.setState({ currentPage: data.selected });
    window.scrollTo(0, 0);
  };

  render() {
    const { currentPage, itemsPerPage } = this.state;
    const {
      items,
      user,
      settings,
      editUserSettings,
      ...restProps
    } = this.props;
    const pageCount = Math.ceil(items.length / itemsPerPage);
    const pageSizesOptions = [
      { label: 3, value: 3 },
      { label: 5, value: 5 },
      { label: 10, value: 10 },
      { label: 20, value: 20 },
    ];
    const prevLabel = <FontAwesomeIcon className="pagination-custom__arrow" icon="chevron-left" />;
    const nextLabel = <FontAwesomeIcon className="pagination-custom__arrow" icon="chevron-right" />;
    const startIndex = currentPage * itemsPerPage;
    const newItems = items.slice(startIndex, startIndex + itemsPerPage);

    return (
      <div>
        <WrappedComponent {...restProps} items={newItems} />
        {items.length !== 0 && (
          <div className="pagination-container">
            <div className="items-to-show">
              <div className="items-to-show__text">Показывать по:</div>
              <StyledSelect
                className="items-to-show__select"
                onChange={this.handlePageSizeSelector}
                options={pageSizesOptions}
                value={{ label: itemsPerPage, value: itemsPerPage }}
                isSearchable={false}
              />
            </div>
            {items.length > itemsPerPage && (
              <ReactPaginate
                previousLabel={prevLabel}
                nextLabel={nextLabel}
                previousClassName="pagination-container__prev"
                nextClassName="pagination-container__next"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageChange}
                containerClassName="pagination-custom"
                subContainerClassName="pagination123"
                activeClassName="active"
                forcePage={currentPage}
              />
            )}
          </div>
        )}
      </div>
    );
  }
};

const mapStateToProps = state => ({
  settings: state.app.settings || { page_sizes: { allVacancies: 10 } },
  user: state.app.user,
});

const mapDispatchToProps = dispatch => ({
  editUserSettings: (id, settings) => dispatch(editUserSettings(id, settings)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withPagination,
);
