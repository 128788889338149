import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { editProfile } from '../actions/app';
import { changePassword, changeEmail } from '../actions/teams';
import ChangePasswordForm from '../forms/ChangePasswordForm';
import ChangeEmailForm from '../forms/ChangeEmailForm';
import ProfileForm from '../forms/ProfileForm';


const SIZE_LIMIT_IN_MEGABYTES = 10;
const BYTES_IN_MEGABYTE = 1048576;
const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png'];

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      avatar: null,
      errorText: '',
    };
    this.fileRef = React.createRef();
  }

  handleChangePasswordForm = (values) => {
    const { changePassword } = this.props;
    const { password, newPassword, reNewPassword } = values;
    changePassword(password, newPassword, reNewPassword);
  };

  handleChangeEmailForm = (values) => {
    const { changeEmail } = this.props;
    const { email } = values;
    changeEmail(email);
  };

  handleSubmit = (values) => {
    const {
      avatar,
    } = this.state;
    const { editProfile, user: { id } } = this.props;
    const {
      first_name,
      last_name,
      phone,
    } = values;

    const updatedUser = new FormData();
    updatedUser.set('id', id);
    updatedUser.set('first_name', first_name);
    updatedUser.set('last_name', last_name);
    updatedUser.set('phone', phone);
    if (avatar) {
      updatedUser.append('avatar', avatar);
    }
    editProfile(id, updatedUser).then(this.resetImage);
  };

  resetImage = () => this.setState({ image: '' });

  onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop();
      if (!ALLOWED_EXTENSIONS.includes(fileExtension)) {
        const errorText = `${file.name} имеет недопустимый формат. Используйте ${ALLOWED_EXTENSIONS.join(', ')}.`;
        this.setState({ errorText });
      } else if (file.size > SIZE_LIMIT_IN_MEGABYTES * BYTES_IN_MEGABYTE) {
        const errorText = `${file.name} превышает максимальный разрешенный размер в ${SIZE_LIMIT_IN_MEGABYTES} МБ.`;
        this.setState({ errorText });
      } else {
        this.setAvatar(file);
        this.setState({ errorText: '' });
      }
    }
  };

  setAvatar = (file) => {
    this.setState({ avatar: file });
    this.getBase64FromBlob(file)
      .then((base64) => {
        this.setState({ image: base64 });
      });
  };

  getBase64FromBlob = blob => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

  onFileClick = () => {
    const fileInput = this.fileRef.current;
    fileInput.click();
  };

  render() {
    const {
      image,
      errorText,
    } = this.state;
    const {
      user: {
        first_name,
        last_name,
        phone,
        position,
        email,
        avatar,
      },
      changePasswordStatus,
    } = this.props;
    return (
      <div className="profile-page">
        <Helmet>
          <title>Профиль | Jobs Widget</title>
        </Helmet>
        <div className="profile-heading">
          <h1 className="main-page-title profile-page__title">Профиль</h1>
          <Row className="justify-content-lg-between w-100 m-0">
            <Col md="6" className="profile-info">
              <div className="profile-avatar mb-4">
                <div className="profile-avatar-pic">
                  <img src={image || avatar} alt="" />
                </div>
                <input
                  className="profile-avatar-file-input"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  ref={this.fileRef}
                  onChange={this.onFileChange}
                />
                <span
                  className="profile-avatar-text light-green"
                  onClick={this.onFileClick}
                >
                  Изменить аватар
                </span>
                {errorText && <div>{errorText}</div>}
              </div>
              <ProfileForm
                onSubmit={this.handleSubmit}
                initialValues={{
                  first_name,
                  last_name,
                  phone,
                  position,
                  email,
                }}
                imageNotSaved={!!image}
              />
            </Col>
            <Col md="6" lg="5" className="profile-data">
              <h4>Сменить пароль</h4>
              <ChangePasswordForm
                onSubmit={this.handleChangePasswordForm}
                changePasswordStatus={changePasswordStatus}
              />

              <h4>Сменить Email</h4>
              <ChangeEmailForm
                onSubmit={this.handleChangeEmailForm}
                initialValues={{ email }}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

ProfilePage.defaultProps = {
  user: {},
  changePasswordStatus: '',
};

ProfilePage.propTypes = {
  user: PropTypes.object,
  editProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  changePasswordStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
  const {
    api: { changePasswordStatus },
    entities: { user },
  } = state;
  return {
    user,
    changePasswordStatus,
  };
};

const mapDispatchToProps = dispatch => ({
  editProfile: (id, user) => dispatch(editProfile(id, user)),
  changePassword: (currentPassword, newPassword, reNewPassword) => dispatch(
    changePassword(currentPassword, newPassword, reNewPassword),
  ),
  changeEmail: email => dispatch(changeEmail(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
