import { useState, useEffect } from 'react';
import { addUniqueKeyForArrayItems } from '../utils/arrayUtils';


const useUniquesKeys = (array) => {
  const [modArray, setModArray] = useState(addUniqueKeyForArrayItems(array));
  const [wasDeleted, setWasDeleted] = useState(true);

  const onDelete = (key) => {
    setWasDeleted(true);
    setTimeout(() => {
      const undeletedFields = modArray.filter(({ id }) => id !== key);
      setModArray(undeletedFields);
    }, 150);
  };

  useEffect(() => {
    if (!wasDeleted) {
      const newArray = addUniqueKeyForArrayItems(array);
      setModArray(modFields => [...modFields, newArray[newArray.length - 1]]);
    }
  }, [array.length]);

  return [onDelete, modArray, setWasDeleted];
};

export default useUniquesKeys;
