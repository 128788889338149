import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class StyledSelect extends Component {
  state = {
    menuIsOpen: false,
  };

  handleOnMenuOpen = () => {
    this.setState({ menuIsOpen: true });
  };

  handleOnMenuClose = () => {
    this.setState({ menuIsOpen: false });
  };

  render() {
    const { menuIsOpen } = this.state;
    const {
      className,
      iconName = null,
      onClickIcon = null,
      altIconText = null,
    } = this.props;

    return (
      <div className="react__select-wrapper">
        <Select
          {...this.props}
          className={`${className} ${menuIsOpen ? 'react-select-menu-is-open' : 'react-select-menu-is-close'}`}
          classNamePrefix="react-select"
          onMenuOpen={this.handleOnMenuOpen}
          onMenuClose={this.handleOnMenuClose}
          noOptionsMessage={() => ('Ничего не найдено')}
        />
        {iconName
        && (
          <FontAwesomeIcon
            className="style-select__icon item-icon"
            icon={iconName}
            size="lg"
            color="grey"
            onClick={onClickIcon}
            title={altIconText}
          />)
        }
      </div>
    );
  }
}

StyledSelect.defaultProps = {
  className: '',
};

StyledSelect.propTypes = {
  className: PropTypes.string,
};

export default StyledSelect;
