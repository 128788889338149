import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatPhone } from '../utils';


const ResponseData = (props) => {
  const { response: { fields, _fields } } = props;
  return Object.keys(fields).map((key) => {
    const { placeholder, type } = _fields.find(field => field.name === key);
    const value = fields[key];
    return (
      <React.Fragment key={key}>
        {value && (
          <div className="applicant__item">
            {type === 'file'
              ? (
                <a
                  key={key}
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    type="button"
                    className="button-dash"
                  >
                    <div className="button-dash__icon">
                      <FontAwesomeIcon
                        icon="file-alt"
                      />
                    </div>
                    {placeholder}
                  </button>
                </a>
              )
              : (
                <React.Fragment>
                  <div className="label applicant__label">{placeholder}</div>
                  <div className="item-text">
                    {type === 'tel' && (
                      <a href={`tel:${formatPhone(value)}`} className="item-text">{formatPhone(value)}</a>
                    )}
                    {type === 'email' && (
                      <a href={`mailto:${value}`} className="item-text">{value}</a>
                    )}
                    {type !== 'tel' && type !== 'email' && value}
                  </div>
                </React.Fragment>
              )}
          </div>
        )}
      </React.Fragment>
    );
  });
};

ResponseData.propTypes = {
  response: PropTypes.object.isRequired,
};

export default ResponseData;
